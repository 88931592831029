export const commissionFeatureKey = 'commission';

export class CommissionModel {
  id: number;
  userId: number;
  contractId: number;
  amount: number;
  commissionPlanRuleId: number;
}

export class CommissionPlanModel {
  id: number;
  name: String;
}
