import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarReferenceExactModelRoutingModule } from './car-reference-exact-model-routing.module';
import { CarReferenceExactModelCreateComponent } from './car-reference-exact-model-create/car-reference-exact-model-create.component';
import { CarReferenceExactModelDetailsComponent } from './car-reference-exact-model-details/car-reference-exact-model-details.component';
import { CarReferenceExactModelEditComponent } from './car-reference-exact-model-edit/car-reference-exact-model-edit.component';
import { CarReferenceExactModelFormContentComponent } from './car-reference-exact-model-form-content/car-reference-exact-model-form-content.component';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    CarReferenceExactModelCreateComponent,
    CarReferenceExactModelDetailsComponent,
    CarReferenceExactModelEditComponent,
    CarReferenceExactModelFormContentComponent,
  ],
  imports: [CommonModule, CarReferenceExactModelRoutingModule, SharedModule],
})
export class CarReferenceExactModelModule {}
