import { CommissionPlanRuleModel } from './types';
import { createApiCallActions } from '../../../../common/store/generic.actions';

export const commissionPlanRuleActions = {
  getById: createApiCallActions<CommissionPlanRuleModel>(
    'commissionPlanRule',
    'getById',
  ),
  getByProductLineId: createApiCallActions<CommissionPlanRuleModel[]>(
    'commissionPlanRule',
    'getByProductLineId',
  ),
};

export type CommissionPlanRuleAction = typeof commissionPlanRuleActions;
