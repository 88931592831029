import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import {
  CounselorModel,
  InsuranceModel,
  RequestStoryModel,
  scraperFeatureKey,
} from './types';
import { IScraperState } from './scraper.reducer';

const scraperFeatureState =
  createFeatureSelector<IScraperState>(scraperFeatureKey);

export const scraperSelectors = {
  getRequestStoriesByLeadId: createApiCallSelectors<
    RequestStoryModel[],
    IScraperState
  >(scraperFeatureState, 'getRequestStoriesByLeadId'),
  scraping: createApiCallSelectors<RequestStoryModel, IScraperState>(
    scraperFeatureState,
    'scraping',
  ),
  getCounselorsByUser: createApiCallSelectors<CounselorModel[], IScraperState>(
    scraperFeatureState,
    'getCounselorsByUser',
  ),
  getCounselorsByUserId: createApiCallSelectors<
    CounselorModel[],
    IScraperState
  >(scraperFeatureState, 'getCounselorsByUserId'),
  addCounselor: createApiCallSelectors<CounselorModel, IScraperState>(
    scraperFeatureState,
    'addCounselor',
  ),
  updatePasswordCounselor: createApiCallSelectors<boolean, IScraperState>(
    scraperFeatureState,
    'updatePasswordCounselor',
  ),
  deleteCounselor: createApiCallSelectors<boolean, IScraperState>(
    scraperFeatureState,
    'deleteCounselor',
  ),
  getInsurancesBySalesTeamId: createApiCallSelectors<
    InsuranceModel[],
    IScraperState
  >(scraperFeatureState, 'getInsurancesBySalesTeamId'),
  addInsurance: createApiCallSelectors<InsuranceModel, IScraperState>(
    scraperFeatureState,
    'addInsurance',
  ),
  updatePasswordInsurance: createApiCallSelectors<boolean, IScraperState>(
    scraperFeatureState,
    'updatePasswordInsurance',
  ),
  deleteInsurance: createApiCallSelectors<boolean, IScraperState>(
    scraperFeatureState,
    'deleteInsurance',
  ),
};
