import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { carReferenceBrandFeatureKey, CarReferenceBrandModel } from './types';
import { ICarReferenceBrandState } from './car-reference-brand.reducer';

const carReferenceBrandFeatureState =
  createFeatureSelector<ICarReferenceBrandState>(carReferenceBrandFeatureKey);

export const carReferenceBrandSelectors = {
  getById: createApiCallSelectors<number, ICarReferenceBrandState>(
    carReferenceBrandFeatureState,
    'getById',
  ),
  createInstance: createApiCallSelectors<void, ICarReferenceBrandState>(
    carReferenceBrandFeatureState,
    'createInstance',
  ),
  add: createApiCallSelectors<CarReferenceBrandModel, ICarReferenceBrandState>(
    carReferenceBrandFeatureState,
    'add',
  ),
  update: createApiCallSelectors<
    CarReferenceBrandModel,
    ICarReferenceBrandState
  >(carReferenceBrandFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, ICarReferenceBrandState>(
    carReferenceBrandFeatureState,
    'delete',
  ),
};
