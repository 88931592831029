import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  carReferenceBrandInitialState,
  carReferenceBrandReducer,
  ICarReferenceBrandState,
} from './car-reference-brand.reducer';
import { carReferenceBrandFeatureKey } from './types';
import { CarReferenceBrandEffects } from './car-reference-brand.effects';
import { CarReferenceBrandService } from './car-reference-brand.service';
import { CarReferenceBrandApiService } from './car-reference-brand.api.service';

@NgModule({
  imports: [
    StoreModule.forFeature<ICarReferenceBrandState>(
      carReferenceBrandFeatureKey,
      carReferenceBrandReducer,
      {
        initialState: carReferenceBrandInitialState,
      },
    ),
    EffectsModule.forFeature([CarReferenceBrandEffects]),
  ],
  providers: [CarReferenceBrandApiService, CarReferenceBrandService],
})
export class StoreCarReferenceBrandModule {}
