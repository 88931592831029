import { Component } from '@angular/core';

@Component({
  selector: 'app-car-reference-model-create',
  templateUrl: './car-reference-model-create.component.html',
  styleUrls: ['./car-reference-model-create.component.scss']
})
export class CarReferenceModelCreateComponent {

}
