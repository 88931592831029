import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  carReferenceExactModelInitialState,
  carReferenceExactModelReducer,
  ICarReferenceExactModelState,
} from './car-reference-exact-model.reducer';
import { carReferenceExactModelFeatureKey } from './types';
import { CarReferenceExactModelEffects } from './car-reference-exact-model.effects';
import { CarReferenceExactModelService } from './car-reference-exact-model.service';
import { CarReferenceExactModelApiService } from './car-reference-exact-model.api.service';

@NgModule({
  imports: [
    StoreModule.forFeature<ICarReferenceExactModelState>(
      carReferenceExactModelFeatureKey,
      carReferenceExactModelReducer,
      {
        initialState: carReferenceExactModelInitialState,
      },
    ),
    EffectsModule.forFeature([CarReferenceExactModelEffects]),
  ],
  providers: [CarReferenceExactModelApiService, CarReferenceExactModelService],
})
export class StoreCarReferenceExactModelModule {}
