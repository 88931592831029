import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BaseServiceFacade,
  GenericApiCall,
} from '../../../../common/store/types';
import { AppState } from '../../types';
import { commissionPlanRuleActions } from './commission-plan-rule.actions';
import { commissionPlanRuleSelectors } from './commission-plan-rule.selector';
import { CommissionPlanRuleModel } from './types';

@Injectable()
export class CommissionPlanRuleService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CommissionPlanRuleModel, string> =
    this.genericApiCall(
      commissionPlanRuleActions.getById,
      commissionPlanRuleSelectors.getById,
      true,
    );
  getByProductLineId: GenericApiCall<CommissionPlanRuleModel[], string> =
    this.genericApiCall(
      commissionPlanRuleActions.getByProductLineId,
      commissionPlanRuleSelectors.getByProductLineId,
      true,
    );
}
