<app-document-subheader></app-document-subheader>
<app-custom-actions-flow-user />
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.user$ | async as user">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate>USER_LBL_NAME</label>
                    <div class="form-title">{{ user.contactName }}</div>
                  </div>
                </div>
                <div class="col-auto ms-auto">
                  <div class="avatar-upload">
                    <div class="avatar-preview rounded-circle shadow-sm position-relative">
                      <div id="imagePreview" class="rounded-circle"
                           [ngStyle]="{ 'background-image': 'url(' + this.apiUrl + user.contactPicturePath + ')'}">
                        <ng-container *ngIf="!user.contactPicturePath">
                          <img *ngIf="true" id="pictureContact"
                               class="picture-control active" src="../../../../assets/images/user.svg"/>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">

                <div class="col-6 col-lg-6">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>USER_LBL_EMAIL</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        <a href="mailto:{{ user.aspNetUserEmail }}"> {{ user.aspNetUserEmail }}</a>
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>USER_LBL_ROLES</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm badge-list">
                          <!-- <app-badge *ngFor="let role of user.roles" [message]="role" color="primary"></app-badge> -->
                          <app-badge-list [InputRoles]="user.rolesLoc" />
                      </span>
                    </div>
                  </div>

                </div>

                <div class="col">



<!--                  <div class="form-group row">-->
<!--                    <label class="col-4 col-form-label-sm" translate>USER_LBL_ACTIVE</label>-->
<!--                    <div class="col">-->
<!--                      <span class="form-control-plaintext form-control-sm">-->
<!--                       <input type="checkbox" name="active" [(ngModel)]="user.aspNetUserEmailConfirmed"-->
<!--                              onclick="return false;" disabled/>-->
<!--                        &lt;!&ndash;   onkeydown="e = e || window.event; if(e.keyCode !== 9) return false;"  &ndash;&gt;-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>

              </div>
              <app-user-tabs-details />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.user$ | async as user">
      <app-comment-panel [entityType]="'User'" [entityId]="user.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
