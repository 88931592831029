import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor, Toolbar } from 'ngx-editor';
import { ArticleService } from 'src/app/shared/store/article/article.service';
import { ArticlePost } from 'src/app/shared/store/article/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';
import { FullPathImagePipe } from '../../../../shared/pipe/full-path-image.pipe';

@UntilDestroy()
@Component({
  selector: 'app-article-create',
  templateUrl: './article-create.component.html',
  styleUrls: ['./article-create.component.scss'],
})
export class ArticleCreateComponent implements OnInit, OnDestroy {
  editor: Editor;
  toolbar: Toolbar;
  isVisible: boolean = false;
  view$: Observable<View>;

  articleForm: FormGroup;

  constructor(
    private readonly articleService: ArticleService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly fullpathImagePipe: FullPathImagePipe,
  ) {}

  ngOnInit(): void {
    this.articleForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
      url: ['', [Validators.required]],
      pageContent: ['', [Validators.required]],
      description: ['', [Validators.required]],
      thumbnail: [null, [Validators.required]],
      header: [null, [Validators.required]],
      imageContent: [null],
      htmlArea: [null],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'articleCreate') this.onSubmit();
        if (x == 'articleList') {
          this.formService.clear();
          this.router.navigate(['Cms/Articles/']);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code', 'blockquote'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
      ['text_color', 'background_color'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    this.view$ = this.viewService.getViews.value$;

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
        this.breadcrumbService.set('@articleCreate', x.breadcrumbCreate);
      });

    this.articleForm
      .get('pageContent')
      ?.valueChanges?.pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.articleForm.patchValue({
          htmlArea: result,
        });
      });
  }

  onSubmit(): void {
    if (this.articleForm.invalid) {
      this.articleForm.markAllAsTouched();
      this.formService.countErrors(this.articleForm, true);
      return;
    }

    this.formService.clear();

    const pageContent = this.fullpathImagePipe.transform(
      this.articleForm.value.pageContent!,
      'ToRelative',
    );

    const articlePost: ArticlePost = {
      name: this.articleForm.value.name!,
      url: this.articleForm.value.url!,
      description: this.articleForm.value.description!,
      pageContent: pageContent,
      thumbnail: this.articleForm.value.thumbnail!,
      header: this.articleForm.value.header!,
      imageContent: this.articleForm.value.imageContent!,
    };

    let result$ = this.articleService.postArticle.call(articlePost);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Cms/Articles/Details', result.page.id]);
      });
  }

  ngOnDestroy(): void {
    this.articleService.postArticle.reset();
    this.editor.destroy();
  }

  onFileEmitterThumbnail(file: File | undefined) {
    this.articleForm.patchValue({
      thumbnail: file,
    });
  }
  onFileEmitterHeader(file: File | undefined) {
    this.articleForm.patchValue({
      header: file,
    });
  }
  onFileEmitterImageContent(file: File | undefined) {
    this.articleForm.patchValue({
      imageContent: file,
    });
  }

  onClickImportHtmlToEditor() {
    this.editor.setContent(this.articleForm.value.htmlArea!);
  }

  onClickShowHtml() {
    this.isVisible = !this.isVisible;
  }
}
