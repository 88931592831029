<app-document-subheader />
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.carReferenceModelModel$ | async as model">
        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row mb-2">
                <div class="col-12 col-md-9 mr-auto">
                  <div class="form-group">
                    <label class="form-title-label" translate>CAR_REFERENCE_MODEL_LBL_NAME</label>
                    <div class="form-title">{{ model.name }}</div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >CAR_REFERENCE_MODEL_LBL_BRAND</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        <app-link-to [id]="model.carReferenceBrandId.toString()"
                                     [label]="model.carReferenceBrandName"
                                     [url]="'/Crm/CarReferenceBrands/Details/'" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
<!--              <app-car-reference-model-tabs-details />-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.carReferenceModelModel$ | async as model">
      <app-comment-panel [entityType]="'CarReferenceModel'" [entityId]="model.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
