import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { carReferenceModelFeatureKey, CarReferenceModelModel } from './types';
import { ICarReferenceModelState } from './car-reference-model.reducer';

const carReferenceModelFeatureState =
  createFeatureSelector<ICarReferenceModelState>(carReferenceModelFeatureKey);

export const carReferenceModelSelectors = {
  getById: createApiCallSelectors<number, ICarReferenceModelState>(
    carReferenceModelFeatureState,
    'getById',
  ),
  createInstance: createApiCallSelectors<void, ICarReferenceModelState>(
    carReferenceModelFeatureState,
    'createInstance',
  ),
  add: createApiCallSelectors<CarReferenceModelModel, ICarReferenceModelState>(
    carReferenceModelFeatureState,
    'add',
  ),
  update: createApiCallSelectors<
    CarReferenceModelModel,
    ICarReferenceModelState
  >(carReferenceModelFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, ICarReferenceModelState>(
    carReferenceModelFeatureState,
    'delete',
  ),
};
