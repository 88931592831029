import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { carReferenceExactModelFeatureKey, CarReferenceExactModelModel } from './types';
import { ICarReferenceExactModelState } from './car-reference-exact-model.reducer';

const carReferenceExactModelFeatureState =
  createFeatureSelector<ICarReferenceExactModelState>(carReferenceExactModelFeatureKey);

export const carReferenceExactModelSelectors = {
  getById: createApiCallSelectors<number, ICarReferenceExactModelState>(
    carReferenceExactModelFeatureState,
    'getById',
  ),
  createInstance: createApiCallSelectors<void, ICarReferenceExactModelState>(
    carReferenceExactModelFeatureState,
    'createInstance',
  ),
  add: createApiCallSelectors<CarReferenceExactModelModel, ICarReferenceExactModelState>(
    carReferenceExactModelFeatureState,
    'add',
  ),
  update: createApiCallSelectors<
    CarReferenceExactModelModel,
    ICarReferenceExactModelState
  >(carReferenceExactModelFeatureState, 'update'),
  delete: createApiCallSelectors<boolean, ICarReferenceExactModelState>(
    carReferenceExactModelFeatureState,
    'delete',
  ),
};
