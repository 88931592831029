import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { ScraperApiService } from './scraper.api.service';
import { ScraperAction, scraperActions } from './scraper.actions';

@Injectable()
export class ScraperEffects {
  constructor(
    private readonly scraperApiService: ScraperApiService,
    private readonly action$: Actions<ScraperAction>,
  ) {}

  getRequestStoriesByLead = createApiCallEffects(
    this,
    this.action$,
    scraperActions.getRequestStoriesByLeadId,
    this.scraperApiService.getRequestStoriesByLeadId,
  );
  scraping = createApiCallEffects(
    this,
    this.action$,
    scraperActions.scraping,
    this.scraperApiService.scraping,
  );
  getInsurancesBySalesTeamId = createApiCallEffects(
    this,
    this.action$,
    scraperActions.getInsurancesBySalesTeamId,
    this.scraperApiService.getInsurancesBySalesTeamId,
  );
  addInsurance = createApiCallEffects(
    this,
    this.action$,
    scraperActions.addInsurance,
    this.scraperApiService.addInsurance,
  );
  updatePasswordInsurance = createApiCallEffects(
    this,
    this.action$,
    scraperActions.updatePasswordInsurance,
    this.scraperApiService.updatePasswordInsurance,
  );
  deleteInsurance = createApiCallEffects(
    this,
    this.action$,
    scraperActions.deleteInsurance,
    this.scraperApiService.deleteInsurance,
  );
  getCounselorsByUser = createApiCallEffects(
    this,
    this.action$,
    scraperActions.getCounselorsByUser,
    this.scraperApiService.getCounselorsByUser,
  );
  getCounselorsByUserId = createApiCallEffects(
    this,
    this.action$,
    scraperActions.getCounselorsByUserId,
    this.scraperApiService.getCounselorsByUserId,
  );
  addCounselor = createApiCallEffects(
    this,
    this.action$,
    scraperActions.addCounselor,
    this.scraperApiService.addCounselor,
  );
  updatePasswordCounselor = createApiCallEffects(
    this,
    this.action$,
    scraperActions.updatePasswordCounselor,
    this.scraperApiService.updatePasswordCounselor,
  );

  deleteCounselor = createApiCallEffects(
    this,
    this.action$,
    scraperActions.deleteCounselor,
    this.scraperApiService.deleteCounselor,
  );
}
