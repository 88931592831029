import { Component } from '@angular/core';

@Component({
  selector: 'app-car-reference-brand-create',
  templateUrl: './car-reference-brand-create.component.html',
  styleUrls: ['./car-reference-brand-create.component.scss']
})
export class CarReferenceBrandCreateComponent {

}
