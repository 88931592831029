import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  BaseServiceFacade,
  GenericApiCall,
} from '../../../../common/store/types';
import { AppState } from '../../types';
import { commissionActions } from './commission.actions';
import { commissionSelectors } from './commission.selector';
import { CommissionModel } from './types';

@Injectable()
export class CommissionService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CommissionModel, string> = this.genericApiCall(
    commissionActions.getById,
    commissionSelectors.getById,
    true,
  );
}
