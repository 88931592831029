import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { take } from 'rxjs/operators';
import { CarReferenceModelModel } from '../../../../shared/store/car-reference-model/types';
import { CarReferenceModelService } from '../../../../shared/store/car-reference-model/car-reference-model.service';

@UntilDestroy()
@Component({
  selector: 'app-car-reference-model-form-content',
  templateUrl: './car-reference-model-form-content.component.html',
  styleUrls: ['./car-reference-model-form-content.component.scss'],
})
export class CarReferenceModelFormContentComponent
  implements OnInit, OnDestroy
{
  view$: Observable<View>;
  carReferenceModelModel: Observable<CarReferenceModelModel>;
  createCarReferenceModelInstance$: Observable<CarReferenceModelModel>;

  carReferenceModelForm: FormGroup;

  isEditMode: boolean = false;
  carReferenceModelId: string;

  constructor(
    private readonly carReferenceModelService: CarReferenceModelService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.carReferenceModelModel = this.carReferenceModelService.getById.value$;
    this.createCarReferenceModelInstance$ =
      this.carReferenceModelService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.carReferenceModelForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(75)]],
      carReferenceBrandId: [undefined, [Validators.required]],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.carReferenceModelId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.carReferenceModelId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.carReferenceModelService.getById.call(this.carReferenceModelId);
    } else {
      // this.carReferenceModelService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'carReferenceModelCreate' || x == 'carReferenceModelUpdate')
          this.onSubmit();
        if (x == 'carReferenceModelList') {
          this.formService.clear();
          this.router.navigate(['Crm/CarReferenceModels/']);
        }
        if (x == 'carReferenceModelDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Crm/CarReferenceModels/Details',
            this.carReferenceModelId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@carReferenceModelCreate',
            x.breadcrumbCreate,
          );
      });

    if (this.isEditMode) {
      this.carReferenceModelModel
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@carReferenceModelEdit', x.name);
          this.carReferenceModelForm.patchValue({
            name: x.name,
            carReferenceBrandId: x.carReferenceBrandId,
          });
        });
    }

    if (!this.isEditMode) {
      // this.createCarReferenceModelInstance$
      //   .pipe(
      //     filter((x) => !!x),
      //     take(1),
      //   )
      //   .subscribe((x) => {
      //     this.carReferenceModelForm?.patchValue({
      //       name: x.name,
      //     });
      //   });
    }
  }

  onSubmit() {
    if (this.carReferenceModelForm.invalid) {
      this.carReferenceModelForm.markAllAsTouched();
      this.formService.setEntityErrors('CAR_REFERENCE_MODEL');
      this.formService.countErrors(this.carReferenceModelForm, true);
      return;
    }

    this.formService.clear();

    const carReferenceModelModel: CarReferenceModelModel = {
      id: this.carReferenceModelId ? +this.carReferenceModelId : 0,
      name: this.carReferenceModelForm.value.name!,
      carReferenceBrandId:
        this.carReferenceModelForm.value.carReferenceBrandId!,
      carReferenceBrandName: '',
    };

    let result$: Observable<CarReferenceModelModel>;
    if (this.isEditMode)
      result$ = this.carReferenceModelService.update.call(
        carReferenceModelModel,
      );
    else
      result$ = this.carReferenceModelService.add.call(carReferenceModelModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/CarReferenceModels/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.carReferenceModelService.add.reset();
    this.carReferenceModelService.update.reset();

    // if (this.isEditMode) {
    //   this.activityService.getById.reset();
    // } else {
    //   this.activityService.createInstance.reset();
    // }

    this.breadcrumbService.set('@carReferenceModelEdit', ' ');
    this.breadcrumbService.set('@carReferenceModelCreate', ' ');
  }
}
