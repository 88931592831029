import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { CarReferenceBrandModel } from './types';
import { carReferenceBrandActions } from './car-reference-brand.action';
import { carReferenceBrandSelectors } from './car-reference-brand.selector';

@Injectable()
export class CarReferenceBrandService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CarReferenceBrandModel, string> = this.genericApiCall(
    carReferenceBrandActions.getById,
    carReferenceBrandSelectors.getById,
    true,
  );
  createInstance: GenericApiCall<CarReferenceBrandModel, void> =
    this.genericApiCall(
      carReferenceBrandActions.createInstance,
      carReferenceBrandSelectors.createInstance,
      true,
    );
  add: GenericApiCall<CarReferenceBrandModel, CarReferenceBrandModel> =
    this.genericApiCall(
      carReferenceBrandActions.add,
      carReferenceBrandSelectors.add,
      true,
    );
  update: GenericApiCall<CarReferenceBrandModel, CarReferenceBrandModel> =
    this.genericApiCall(
      carReferenceBrandActions.update,
      carReferenceBrandSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    carReferenceBrandActions.delete,
    carReferenceBrandSelectors.delete,
    true,
  );
}
