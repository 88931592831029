import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, Observable } from 'rxjs';
import { ArticleService } from 'src/app/shared/store/article/article.service';
import { Article } from 'src/app/shared/store/article/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter, take } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';
import { FullPathImagePipe } from '../../../../shared/pipe/full-path-image.pipe';

@UntilDestroy()
@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
})
export class ArticleDetailComponent implements OnInit, OnDestroy {
  articleId: string;
  article$: Observable<Article>;
  view$: Observable<View>;

  constructor(
    private readonly articleService: ArticleService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
  ) {
    this.article$ = this.articleService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.articleId = this.route.snapshot.params['id'];
    this.articleService.getById.call(this.articleId);

    this.article$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@articleDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x === 'articleDelete') {
          this.onDelete();
        }
        if (x === 'articlePublish') this.publishContent();
        if (x === 'articleUnpublish') this.unpublishContent();
        if (x === 'articleEdit') {
          this.router.navigate(['Cms/Articles/Edit/', this.articleId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  publishContent() {
    let resultPublish$ = this.articleService.publishArticle.call(
      this.route.snapshot.params['id'],
    ) as Observable<Article>;

    resultPublish$
      .pipe(
        debounceTime(200),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.articleService.getById.call(x.id.toString());
        this.articleService.publishArticle.reset();
      });
  }

  unpublishContent() {
    let resultUnpublish$ = this.articleService.unpublishArticle.call(
      this.route.snapshot.params['id'],
    ) as Observable<Article>;

    resultUnpublish$
      .pipe(
        debounceTime(200),
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.articleService.getById.call(x.id.toString());
        this.articleService.unpublishArticle.reset();
      });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.set('@articleDetails', ' ');
    // this.articleService.getById.reset();
    this.articleService.unpublishArticle.reset();
    this.articleService.publishArticle.reset();
    this.articleService.delete.reset();
  }

  onDelete() {
    let result$ = this.articleService.delete.call(this.articleId);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.articleService.delete.reset();
      this.router.navigate(['Cms/Articles/List']);
    });
  }
}
