import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ScraperApiService } from './scraper.api.service';
import {
  IScraperState,
  scraperInitialState,
  scraperReducer,
} from './scraper.reducer';
import { ScraperEffects } from './scraper.effects';
import { ScraperService } from './scraper.service';
import { scraperFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IScraperState>(
      scraperFeatureKey,
      scraperReducer,
      {
        initialState: scraperInitialState,
      },
    ),
    EffectsModule.forFeature([ScraperEffects]),
  ],
  providers: [ScraperApiService, ScraperService],
})
export class StoreScraperModule {}
