import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { ProductModel } from './types';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  getById = (id: number): Observable<ProductModel> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ProductModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  add = (productModel: ProductModel): Observable<ProductModel> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<ProductModel>(url, productModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  update = (productModel: ProductModel): Observable<ProductModel> => {
    let parameters: string[];
    parameters = [productModel.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.put<ProductModel>(url, productModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };
  createInstance = (): Observable<ProductModel> => {
    let parameters: string[];
    parameters = ['create'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ProductModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  // getListItems = (): Observable<ProductModel> => {
  //   let parameters: string[];
  //   parameters = ['create'];
  //   let url = this.formatUrl(parameters);
  //   return this.httpClient.get<ProductModel>(url).pipe(
  //     catchError((error) => {
  //       throw this.errorService.serverErrorRedirect(error);
  //     })
  //   );
  // };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.product.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
