import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CounselorModel,
  RequestStoryModel,
} from '../../../../../../shared/store/scraper/types';
import { Observable } from 'rxjs';
import { ScraperService } from '../../../../../../shared/store/scraper/scraper.service';
import { ActivatedRoute } from '@angular/router';
import { ButtonType } from '../../../../../../shared/store/subheader/type';
import { filterTrue } from '../../../../../../shared/pipe/rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalContentComponent } from '../../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../../shared/components/modal-content/action-button';
import { SourceEnum } from '../../../../../../shared/store/typeahead/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../../../../shared/service/modal.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../../../shared/store/form/form.service';
import { LeadService } from '../../../../../../shared/store/lead/lead.service';
import {
  CarInsuranceLeadModel,
  LeadDetailModel,
  LeadModel,
} from '../../../../../../shared/store/lead/types';
import { take } from 'rxjs/operators';
import {
  removeValidators,
  setValidators,
} from '../../../../../../shared/helper/formhelper';

@UntilDestroy()
@Component({
  selector: 'app-lead-details-quotes-scraper',
  templateUrl: './lead-details-quotes-scraper.component.html',
  styleUrls: ['./lead-details-quotes-scraper.component.scss'],
})
export class LeadDetailsQuotesScraperComponent implements OnInit, OnDestroy {
  requestStories$: Observable<RequestStoryModel[]>;
  requestStory$: Observable<RequestStoryModel>;
  counselors$: Observable<CounselorModel[]>;
  leadDetail$: Observable<LeadDetailModel>;
  carDetail$: Observable<CarInsuranceLeadModel>;

  leadUniqueId: string;

  validationForm: FormGroup;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly scraperService: ScraperService,
    private readonly modalService: NgbModal,
    private readonly modalExchangeService: ModalService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly leadService: LeadService,
  ) {
    this.requestStories$ = this.scraperService.getRequestStoriesByLeadId.value$;
    this.counselors$ = this.scraperService.getCounselorsByUser.value$;
    this.requestStory$ = this.scraperService.scraping.value$;
    this.leadDetail$ = this.leadService.getByUniqueId.value$;
    this.carDetail$ = this.leadService.getCarDetail.value$;
  }

  ngOnInit(): void {
    this.leadUniqueId = this.route.snapshot.params['id'];
    this.scraperService.getRequestStoriesByLeadId.call(this.leadUniqueId);

    this.counselors$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((counselors) => {
        if (counselors.length == 0) {
          return;
        } else {
          if (counselors.length == 1) {
            this.scraperService.scraping.call({
              leadUniqueId: this.leadUniqueId,
              ocealizCounselorId: counselors[0].id.toString(),
            });
          } else {
            this.openCounselorSelectionUser(counselors);
          }
          this.scraperService.getCounselorsByUser.reset();
        }
      });

    this.requestStory$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((result) => {
        this.scraperService.getRequestStoriesByLeadId.call(this.leadUniqueId);
        this.scraperService.scraping.reset();
      });

    this.validationForm = this.formBuilder.group({
      qualifyContact: [undefined],
      // address: [undefined, [Validators.required]],
      expectedContractStartDate: [undefined, [Validators.required]],
      uniqueId: [undefined, [Validators.required]],
      category: [undefined, [Validators.required]],
      categoryLoc: [undefined, [Validators.required]],
      productCategoryId: [undefined, [Validators.required]],
      contactFirstName: [undefined, [Validators.required]],
      contactLastName: [undefined, [Validators.required]],
      contactAddress: [undefined, [Validators.required]],
      contactZipCode: [undefined, [Validators.required]],
      contactCity: [undefined, [Validators.required]],
      contactPhoneNumber: [undefined, [Validators.required]],
      contactEmail: [undefined, [Validators.required]],
      creationDate: [undefined, [Validators.required]],
      // contactFullCity: [undefined, [Validators.required]],
      // contactName: [undefined, [Validators.required]],
      // name: [undefined, [Validators.required]],
      // firstName: [undefined, [Validators.required]],
      // lastName: [undefined, [Validators.required]],
      // phoneNumber: [undefined, [Validators.required]],
      // email: [undefined, [Validators.required]],
      // zipCode: [undefined, [Validators.required]],
      // city: [undefined, [Validators.required]],
      // fullCity: [undefined, [Validators.required]],
      leadStatus: [undefined, [Validators.required]],
      // leadStatusLoc: [undefined, [Validators.required]],

      // leadId: [undefined, [Validators.required]],
      driverFirstName: [undefined, [Validators.required]],
      driverLastName: [undefined, [Validators.required]],
      driverBirthdate: [undefined, [Validators.required]],
      driverZipCode: [undefined, [Validators.required]],
      driverCity: [undefined, [Validators.required]],
      driverAddress: [undefined, [Validators.required]],
      driverMaritalStatus: [undefined, [Validators.required]],
      licenseDate: [undefined, [Validators.required]],
      isDriverVehiculeRegistrationHolder: [undefined, [Validators.required]],
      // vehiculeRegistrationHolder: [undefined, [Validators.required]],
      // vehiculeRegistrationHolderNameLastName: [
      //   undefined,
      //   [Validators.required],
      // ],
      // vehiculeRegistrationHolderNameFirstName: [
      //   undefined,
      //   [Validators.required],
      // ],
      // vehiculeRegistrationHolderNameCompanyName: [
      //   undefined,
      //   [Validators.required],
      // ],
      // vehiculeRegistrationHolderBirthdate: [undefined, [Validators.required]],
      yearsInsured: [undefined, [Validators.required]],
      bonusMalus: [undefined, [Validators.required]],
      // alcoholDrugControl: [undefined, [Validators.required]],
      // nbCarDisaster: [undefined, [Validators.required]],
      licensePlateNumber: [undefined, [Validators.required]],
      brand: [undefined, [Validators.required]],
      model: [undefined, [Validators.required]],
      exactModel: [undefined, [Validators.required]],
      carBody: [undefined, [Validators.required]],
      carEnergie: [undefined, [Validators.required]],
      carPower: [undefined, [Validators.required]],
      vehiculeUsage: [undefined, [Validators.required]],
      // vehiculeUsageProSupp: [undefined, [Validators.required]],
      dateOfCirculation: [undefined, [Validators.required]],
      contractStartDate: [undefined, [Validators.required]],
    });

    this.leadDetail$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((result) => {
        if (!result.contactId) {
          //If the contact is not qualified, it becomes required
          setValidators(
            this.validationForm.get('qualifyContact'),
            Validators.required,
          );
          //And fields contact not required to not be showned in the validation notif
          if (result.firstName) {
            removeValidators(
              this.validationForm.get('contactFirstName'),
              Validators.required,
            );
          }
          if (result.lastName) {
            removeValidators(
              this.validationForm.get('contactLastName'),
              Validators.required,
            );
          }
          if (result.address) {
            removeValidators(
              this.validationForm.get('contactAddress'),
              Validators.required,
            );
          }
          if (result.zipCode) {
            removeValidators(
              this.validationForm.get('contactZipCode'),
              Validators.required,
            );
          }
          if (result.city) {
            removeValidators(
              this.validationForm.get('contactCity'),
              Validators.required,
            );
          }
          if (result.phoneNumber) {
            removeValidators(
              this.validationForm.get('contactPhoneNumber'),
              Validators.required,
            );
          }
          if (result.email) {
            removeValidators(
              this.validationForm.get('contactEmail'),
              Validators.required,
            );
          }
        } else {
          removeValidators(
            this.validationForm.get('qualifyContact'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactFirstName'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactLastName'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactAddress'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactZipCode'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactCity'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactPhoneNumber'),
            Validators.required,
          );
          setValidators(
            this.validationForm.get('contactEmail'),
            Validators.required,
          );
        }
        this.validationForm.patchValue({
          // address: result.address,
          expectedContractStartDate: result.expectedContractStartDate,
          uniqueId: result.uniqueId,
          category: result.category,
          categoryLoc: result.categoryLoc,
          productCategoryId: result.productCategoryId,
          contactFirstName: result.contactFirstName,
          contactLastName: result.contactLastName,
          contactAddress: result.contactAddress,
          contactZipCode: result.contactZipCode,
          contactCity: result.contactCity,
          contactPhoneNumber: result.contactPhoneNumber,
          contactEmail: result.contactEmail,
          creationDate: result.creationDate,
          // contactName: result.contactName,
          // name: result.name,
          // firstName: result.firstName,
          // lastName: result.lastName,
          // phoneNumber: result.phoneNumber,
          // email: result.email,
          // zipCode: result.zipCode,
          // city: result.city,
          // fullCity: result.fullCity,
          leadStatus: result.leadStatus,
        });
      });

    this.carDetail$
      .pipe(filterTrue(), untilDestroyed(this))
      .subscribe((result) => {
        this.validationForm.patchValue({
          driverFirstName: result.driverFirstName,
          driverLastName: result.driverLastName,
          driverBirthdate: result.driverBirthdate,
          driverZipCode: result.driverZipCode,
          driverCity: result.driverCity,
          driverAddress: result.driverAddress,
          driverMaritalStatus: result.driverMaritalStatus,
          licenseDate: result.licenseDate,
          isDriverVehiculeRegistrationHolder:
            result.isDriverVehiculeRegistrationHolder,
          // vehiculeRegistrationHolder: result.vehiculeRegistrationHolder,
          // vehiculeRegistrationHolderNameLastName:
          //   result.vehiculeRegistrationHolderNameLastName,
          // vehiculeRegistrationHolderNameFirstName:
          //   result.vehiculeRegistrationHolderNameFirstName,
          // vehiculeRegistrationHolderNameCompanyName:
          //   result.vehiculeRegistrationHolderCompanyName,
          // vehiculeRegistrationHolderBirthdate:
          //   result.vehiculeRegistrationHolderBirthdate,
          yearsInsured: result.yearsInsured,
          bonusMalus: result.bonusMalus,
          // alcoholDrugControl: result.alcoholDrugControl,
          // nbCarDisaster: result.nbCarDisaster,
          licensePlateNumber: result.licensePlateNumber,
          brand: result.brand,
          model: result.model,
          exactModel: result.exactModel,
          carBody: result.carBody,
          carEnergie: result.carEnergie,
          carPower: result.carPower,
          vehiculeUsage: result.vehiculeUsage,
          // vehiculeUsageProSupp: result.vehiculeUsageProSupp,
          dateOfCirculation: result.dateOfCirculation,
          contractStartDate: result.contractStartDate,
        });
      });
  }

  addNewQuoteOcealiz() {
    //Add validation before call the scraper
    if (this.validationForm.invalid) {
      this.validationForm.markAllAsTouched();
      this.formService.setEntityErrors('VALIDATION_LEAD');
      this.formService.countErrors(this.validationForm, true);
      return;
    }
    this.scraperService.getCounselorsByUser.call();
  }

  openCounselorSelectionUser(counselors: CounselorModel[]) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: true,
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'CounselorChoiceQuoteOcealizComponent',
      );
    modalRef.componentInstance.title = 'LEAD_LBL_QUOTES_SCRAPER_ADD';

    modalRef.componentInstance.closeCrossButton = true;
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        label: 'SCRAPER_QUOTE_LBL_QUOTE_ADD',
        actionName: 'scrapingQuote',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      counselors: counselors,
      leadUniqueId: this.leadUniqueId,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          //todo refresh devis ?
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  ngOnDestroy(): void {
    this.scraperService.getRequestStoriesByLeadId.reset();
    this.scraperService.scraping.reset();
    this.scraperService.getCounselorsByUser.reset();
  }
}
