import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { CarReferenceModelModel } from './types';
import { carReferenceModelActions } from './car-reference-model.action';
import { carReferenceModelSelectors } from './car-reference-model.selector';

@Injectable()
export class CarReferenceModelService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CarReferenceModelModel, string> = this.genericApiCall(
    carReferenceModelActions.getById,
    carReferenceModelSelectors.getById,
    true,
  );
  createInstance: GenericApiCall<CarReferenceModelModel, void> =
    this.genericApiCall(
      carReferenceModelActions.createInstance,
      carReferenceModelSelectors.createInstance,
      true,
    );
  add: GenericApiCall<CarReferenceModelModel, CarReferenceModelModel> =
    this.genericApiCall(
      carReferenceModelActions.add,
      carReferenceModelSelectors.add,
      true,
    );
  update: GenericApiCall<CarReferenceModelModel, CarReferenceModelModel> =
    this.genericApiCall(
      carReferenceModelActions.update,
      carReferenceModelSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    carReferenceModelActions.delete,
    carReferenceModelSelectors.delete,
    true,
  );
}
