import { createApiCallActions } from '../../../common/store/generic.actions';
import { CarReferenceBrandModel } from './types';

export const carReferenceBrandActions = {
  getById: createApiCallActions<CarReferenceBrandModel>(
    'carReferenceBrand',
    'getById',
  ),
  createInstance: createApiCallActions<CarReferenceBrandModel>(
    'carReferenceBrand',
    'createInstance',
  ),
  add: createApiCallActions<CarReferenceBrandModel>('carReferenceBrand', 'add'),
  update: createApiCallActions<CarReferenceBrandModel>(
    'carReferenceBrand',
    'update',
  ),
  delete: createApiCallActions<boolean>('carReferenceBrand', 'delete'),
};

export type CarReferenceBrandAction = typeof carReferenceBrandActions;
