import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScraperService } from '../../../../../../../shared/store/scraper/scraper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../../../../../shared/store/form/form.service';
import { Observable } from 'rxjs';
import { SalesTeamModel } from '../../../../../../../shared/store/sales-team/types';
import { SalesTeamService } from '../../../../../../../shared/store/sales-team/sales-team.service';
import { take } from 'rxjs/operators';
import { filterTrue } from '../../../../../../../shared/pipe/rxjs/operators';
import { InsuranceModel } from '../../../../../../../shared/store/scraper/types';
import { ModalService } from '../../../../../../../shared/service/modal.service';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from 'src/app/shared/store/typeahead/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubHeaderService } from '../../../../../../../shared/store/subheader/subheader.service';

@UntilDestroy()
@Component({
  selector: 'app-external-account-create',
  templateUrl: './sales-team-external-account-create-content.component.html',
  styleUrls: ['./sales-team-external-account-create-content.component.scss'],
})
export class SalesTeamExternalAccountCreateContentComponent
  implements OnInit, OnDestroy
{
  @Input() source: SourceEnum = SourceEnum.Component;

  salesteamId: number;
  salesteam$: Observable<SalesTeamModel>;

  insuranceForm: FormGroup;

  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly scraperService: ScraperService,
    private readonly salesTeamService: SalesTeamService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly dynamicService: ModalService,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.salesteam$ = this.salesTeamService.getById.value$;
  }

  ngOnInit(): void {
    this.insuranceForm = this.formBuilder.group({
      externalAccountType: [undefined, [Validators.required]],
      label: ['', [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    this.salesteam$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.salesteamId = result.id;
      this.insuranceForm.patchValue({
        label: result.name,
      });
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'insuranceCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit() {
    if (this.insuranceForm.invalid) {
      this.insuranceForm.markAllAsTouched();
      this.formService.setEntityErrors('INSURANCE');
      this.formService.countErrors(this.insuranceForm, true);
      return;
    }

    const insuranceModel: InsuranceModel = {
      id: 0,
      name: '',
      label: this.insuranceForm.value.label!,
      externalAccountType: this.insuranceForm.value.externalAccountType!,
      username: this.insuranceForm.value.username!,
      password: this.insuranceForm.value.password!,
      salesTeamId: this.salesteamId,
      externalAccountTypeLoc: '',
      actions: [],
    };

    console.log(insuranceModel);

    let result$ = this.scraperService.addInsurance.call(insuranceModel);

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.formService.clear();
      switch (this.source) {
        case SourceEnum.Component:
          break;
        case SourceEnum.Modal:
          this.dynamicService.outputFromDynamicComponent(result.id.toString());
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.scraperService.addInsurance.reset();
  }
}
