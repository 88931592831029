export const carReferenceExactModelFeatureKey = 'carReferenceExactModel';

export type CarReferenceExactModelModel = {
  id: number;
  name: string;
  carReferenceModelId: number;
  carReferenceModelName: string;
  carReferenceModelCarReferenceBrandId: number;
  carReferenceModelCarReferenceBrandName: string;
};
