import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  CarReferenceModelAction,
  carReferenceModelActions,
} from './car-reference-model.action';
import { CarReferenceModelApiService } from './car-reference-model.api.service';

@Injectable()
export class CarReferenceModelEffects {
  constructor(
    private readonly carReferenceModelApiService: CarReferenceModelApiService,
    private readonly action$: Actions<CarReferenceModelAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    carReferenceModelActions.getById,
    this.carReferenceModelApiService.getById,
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    carReferenceModelActions.createInstance,
    this.carReferenceModelApiService.createInstance,
  );
  add = createApiCallEffects(
    this,
    this.action$,
    carReferenceModelActions.add,
    this.carReferenceModelApiService.add,
  );
  update = createApiCallEffects(
    this,
    this.action$,
    carReferenceModelActions.update,
    this.carReferenceModelApiService.update,
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    carReferenceModelActions.delete,
    this.carReferenceModelApiService.delete,
  );
}
