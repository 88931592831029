<form [formGroup]="counselorChoiceForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="counselorChoiceForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >SCRAPER_QUOTE_LBL_CHOICE_COUSELOR</label
      >
      <div class="col">
        <app-typeahead
          [data]="this.listItems"
          [dataValueMember]="'id'"
          [dataDisplayMember]="'name'"
          [controlForm]="this.counselorChoiceForm.controls['counselorId']"
          [allowCustomActions]="false"
          [limitToList]="true"
        ></app-typeahead>
      </div>
    </div>
  </div>
</form>
