import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../common/store/types';
import { CounselorModel, InsuranceModel, RequestStoryModel } from './types';
import { scraperActions } from './scraper.actions';

export interface IScraperState extends IState<RequestStoryModel> {
  getRequestStoriesByLeadId: GenericState<RequestStoryModel[]>;
  scraping: GenericState<RequestStoryModel>;
  getInsurancesBySalesTeamId: GenericState<InsuranceModel[]>;
  addInsurance: GenericState<InsuranceModel>;
  updatePasswordInsurance: GenericState<boolean>;
  deleteInsurance: GenericState<boolean>;
  getCounselorsByUser: GenericState<CounselorModel[]>;
  getCounselorsByUserId: GenericState<CounselorModel[]>;
  addCounselor: GenericState<CounselorModel>;
  updatePasswordCounselor: GenericState<boolean>;
  deleteCounselor: GenericState<boolean>;
}

export const scraperInitialState: IScraperState = {
  getRequestStoriesByLeadId: new GenericState<RequestStoryModel[]>(),
  scraping: new GenericState<RequestStoryModel>(),
  getInsurancesBySalesTeamId: new GenericState<InsuranceModel[]>(),
  addInsurance: new GenericState<InsuranceModel>(),
  updatePasswordInsurance: new GenericState<boolean>(),
  deleteInsurance: new GenericState<boolean>(),
  getCounselorsByUser: new GenericState<CounselorModel[]>(),
  getCounselorsByUserId: new GenericState<CounselorModel[]>(),
  addCounselor: new GenericState<CounselorModel>(),
  updatePasswordCounselor: new GenericState<boolean>(),
  deleteCounselor: new GenericState<boolean>(),
};

const scraperReducers = [
  ...onApiCall<RequestStoryModel[]>(
    scraperActions.getRequestStoriesByLeadId,
    'getRequestStoriesByLeadId',
  ),
  ...onApiCall<RequestStoryModel>(scraperActions.scraping, 'scraping'),
  ...onApiCall<CounselorModel[]>(
    scraperActions.getCounselorsByUser,
    'getCounselorsByUser',
  ),
  ...onApiCall<CounselorModel[]>(
    scraperActions.getCounselorsByUserId,
    'getCounselorsByUserId',
  ),
  ...onApiCall<CounselorModel>(scraperActions.addCounselor, 'addCounselor'),
  ...onApiCall<boolean>(
    scraperActions.updatePasswordCounselor,
    'updatePasswordCounselor',
  ),
  ...onApiCall<boolean>(scraperActions.deleteCounselor, 'deleteCounselor'),
  ...onApiCall<InsuranceModel[]>(
    scraperActions.getInsurancesBySalesTeamId,
    'getInsurancesBySalesTeamId',
  ),
  ...onApiCall<InsuranceModel>(scraperActions.addInsurance, 'addInsurance'),
  ...onApiCall<boolean>(
    scraperActions.updatePasswordInsurance,
    'updatePasswordInsurance',
  ),
  ...onApiCall<boolean>(scraperActions.deleteInsurance, 'deleteInsurance'),
];

export const scraperReducer = createReducer(
  scraperInitialState,
  ...scraperReducers,
) as ActionReducer<IScraperState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return scraperReducer(state, action);
}
