import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsContentUser } from '../../../../../../shared/models/tabs-content-user';
import { UserDetailsExternalAccountComponent } from '../user-details-tab/user-details-external-account/user-details-external-account.component';

@Component({
  selector: 'app-user-tabs-details',
  templateUrl: './user-tabs-details.component.html',
  styleUrls: ['./user-tabs-details.component.scss'],
})
export class UserTabsDetailsComponent implements OnInit, OnChanges, OnDestroy {
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContentUser[]> = new BehaviorSubject<
    TabsContentUser[]
  >([]);

  tabsInit: TabsContentUser[] = [
    {
      title: 'USER_TABS_EXTERNAL_ACCOUNT',
      index: 1,
      name: 'external',
      component: UserDetailsExternalAccountComponent,
    },
  ];

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentUser) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
