import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { CarReferenceExactModelModel } from './types';
import { carReferenceExactModelActions } from './car-reference-exact-model.action';
import { carReferenceExactModelSelectors } from './car-reference-exact-model.selector';

@Injectable()
export class CarReferenceExactModelService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<CarReferenceExactModelModel, string> = this.genericApiCall(
    carReferenceExactModelActions.getById,
    carReferenceExactModelSelectors.getById,
    true,
  );
  createInstance: GenericApiCall<CarReferenceExactModelModel, void> =
    this.genericApiCall(
      carReferenceExactModelActions.createInstance,
      carReferenceExactModelSelectors.createInstance,
      true,
    );
  add: GenericApiCall<CarReferenceExactModelModel, CarReferenceExactModelModel> =
    this.genericApiCall(
      carReferenceExactModelActions.add,
      carReferenceExactModelSelectors.add,
      true,
    );
  update: GenericApiCall<CarReferenceExactModelModel, CarReferenceExactModelModel> =
    this.genericApiCall(
      carReferenceExactModelActions.update,
      carReferenceExactModelSelectors.update,
      true,
    );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    carReferenceExactModelActions.delete,
    carReferenceExactModelSelectors.delete,
    true,
  );
}
