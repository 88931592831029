import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesTeamRoutingModule } from './sales-team-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { SalesTeamDetailsComponent } from './sales-team-details/sales-team-details.component';
import { SalesTeamCreateComponent } from './sales-team-create/sales-team-create.component';
import { SalesTeamEditComponent } from './sales-team-edit/sales-team-edit.component';
import { SalesTeamReportingComponent } from './sales-team-reporting/sales-team-reporting.component';
import { SalesTeamMembersReportingComponent } from './sales-team-members-reporting/sales-team-members-reporting.component';
import { SalesTeamTabsDetailsComponent } from './sales-team-details/sales-team-tabs-details/sales-team-tabs-details.component';
import { SalesTeamDetailsExternalAccountComponent } from './sales-team-details/sales-team-details-tab/sales-team-details-external-account/sales-team-details-external-account.component';
import { SalesTeamExternalAccountCreateContentComponent } from './sales-team-details/sales-team-details-tab/sales-team-details-external-account/sales-team-external-account-create-content/sales-team-external-account-create-content.component';

@NgModule({
  declarations: [
    SalesTeamDetailsComponent,
    SalesTeamCreateComponent,
    SalesTeamEditComponent,
    SalesTeamReportingComponent,
    SalesTeamMembersReportingComponent,
    SalesTeamTabsDetailsComponent,
    SalesTeamDetailsExternalAccountComponent,
    SalesTeamExternalAccountCreateContentComponent,
  ],
  imports: [CommonModule, SalesTeamRoutingModule, SharedModule],
})
export class SalesTeamModule {}
