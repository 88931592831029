<form [formGroup]="counselorForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="counselorForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">

    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >EXTERNAL_ACCOUNT_LBL_OCEALIZ_INSURANCE</label
      >
      <div class="col">
        <app-typeahead
          [routeEndpoint]="'OcealizScrapers/Insurances'"
          [dropdownStyle]="DropdownStyle.DropDownList"
          [controlForm]="this.counselorForm.controls['insuranceId']"
          [limitToList]="true"
          [orderBy]="'label asc'"
        ></app-typeahead>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >EXTERNAL_ACCOUNT_LBL_USERNAME</label
      >
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="text"
          [placeholder]="''"
          formControlName="username"
          autocomplete="new-password"
          checkFormFieldValidity
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >EXTERNAL_ACCOUNT_LBL_PASSWORD</label
      >
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="password"
          [placeholder]="''"
          formControlName="password"
          autocomplete="new-password"
          checkFormFieldValidity
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >EXTERNAL_ACCOUNT_LBL_IS_PRIMARY</label
      >
      <div class="col">
        <input
          class="form-control-sm"
          id="isPrimary"
          name="isPrimary"
          type="checkbox"
          value=""
          formControlName="isPrimary" [defaultChecked]="false"
          checkFormFieldValidity
        />
      </div>
    </div>
  </div>
</form>
