import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor, Toolbar } from 'ngx-editor';
import { filter, Observable } from 'rxjs';
import { ArticleService } from 'src/app/shared/store/article/article.service';
import { Article } from 'src/app/shared/store/article/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { decodeHtml } from '../../../../shared/helper/stringhelper';
import { FullPathImagePipe } from '../../../../shared/pipe/full-path-image.pipe';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-article-edit',
  templateUrl: './article-edit.component.html',
  styleUrls: ['./article-edit.component.scss'],
})
export class ArticleEditComponent implements OnInit, OnDestroy {
  article$: Observable<Article>;
  articleForm: FormGroup;

  editor: Editor;
  toolbar: Toolbar;
  isVisible: boolean = false;

  view$: Observable<View>;

  constructor(
    private readonly articleService: ArticleService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.article$ = this.articleService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.articleForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
      url: ['', [Validators.required]],
      pageContent: ['', [Validators.required]],
      description: ['', [Validators.required]],
      thumbnail: [null],
      header: [null],
      imageContent: [null],
      htmlArea: [null],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'articleUpdate') this.onSubmit();
        if (x == 'articleDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Cms/Articles/Details',
            this.route.snapshot.params['id'],
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });

    this.articleService.getById.call(this.route.snapshot.params['id']);

    this.article$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@articleEdit', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code', 'blockquote'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
      ['text_color', 'background_color'],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    this.article$.pipe().subscribe((x) => {
      this.articleForm.patchValue({
        name: x.name,
        url: x.url,
        pageContent: new FullPathImagePipe().transform(
          x.pageContent,
          'ToAbsolute',
        ),
        description: decodeHtml(x.description),
      });
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'articleCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.articleForm
      .get('pageContent')
      ?.valueChanges?.pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.articleForm.patchValue({
          htmlArea: result,
        });
      });
  }

  onSubmit(): void {
    if (this.articleForm.invalid) {
      this.articleForm.markAllAsTouched();
      this.formService.countErrors(this.articleForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.articleService.updateArticle.call({
      id: this.route.snapshot.params['id'],
      name: this.articleForm.value.name!,
      url: this.articleForm.value.url!,
      pageContent: new FullPathImagePipe().transform(
        this.articleForm.value.pageContent!,
        'ToRelative',
      ),
      // pageContent: this.articleForm.value.pageContent!,
      description: this.articleForm.value.description!,
      thumbnail: this.articleForm.value.thumbnail!,
      header: this.articleForm.value.header!,
      imageContent: this.articleForm.value.imageContent!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Cms/Articles/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.articleService.updateArticle.reset();
    this.breadcrumbService.set('@articleDetails', ' ');
    // this.articleService.getById.reset();
  }

  onFileEmitterThumbnail(file: File | undefined) {
    this.articleForm.patchValue({
      thumbnail: file,
    });
  }
  onFileEmitterHeader(file: File | undefined) {
    this.articleForm.patchValue({
      header: file,
    });
  }
  onFileEmitterImageContent(file: File | undefined) {
    this.articleForm.patchValue({
      imageContent: file,
    });
  }

  onClickImportHtmlToEditor() {
    this.editor.setContent(this.articleForm.value.htmlArea!);
  }

  onClickShowHtml() {
    this.isVisible = !this.isVisible;
  }
}
