import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { CarReferenceBrandModel } from './types';
import { carReferenceBrandActions } from './car-reference-brand.action';

export interface ICarReferenceBrandState
  extends IState<CarReferenceBrandModel> {
  getById: GenericState<CarReferenceBrandModel>;
  createInstance: GenericState<CarReferenceBrandModel>;
  add: GenericState<CarReferenceBrandModel>;
  update: GenericState<CarReferenceBrandModel>;
  delete: GenericState<boolean>;
}

export const carReferenceBrandInitialState: ICarReferenceBrandState = {
  getById: new GenericState<CarReferenceBrandModel>(),
  createInstance: new GenericState<CarReferenceBrandModel>(),
  add: new GenericState<CarReferenceBrandModel>(),
  update: new GenericState<CarReferenceBrandModel>(),
  delete: new GenericState<boolean>(),
};

const carReferenceBrandReducers = [
  ...onApiCall<CarReferenceBrandModel>(
    carReferenceBrandActions.getById,
    'getById',
  ),
  ...onApiCall<CarReferenceBrandModel>(
    carReferenceBrandActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<CarReferenceBrandModel>(carReferenceBrandActions.add, 'add'),
  ...onApiCall<CarReferenceBrandModel>(
    carReferenceBrandActions.update,
    'update',
  ),
  ...onApiCall<boolean>(carReferenceBrandActions.delete, 'delete'),
];

export const carReferenceBrandReducer = createReducer(
  carReferenceBrandInitialState,
  ...carReferenceBrandReducers,
) as ActionReducer<ICarReferenceBrandState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return carReferenceBrandReducer(state, action);
}
