import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BankAccountEdit } from '../../../../../../shared/components/bank-account-edit/bank-account-edit';
import {
  removeValidators,
  setValidators,
} from '../../../../../../shared/helper/formhelper';
import { ContactService } from '../../../../../../shared/store/contact/contact.service';
import { ContactModel } from '../../../../../../shared/store/contact/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { ContractModel } from '../../../../../../shared/store/contract/types';
import { PaymentSplittingService } from '../../../../../../shared/store/payment-splitting/payment-splitting.service';
import { PaymentSplitting } from '../../../../../../shared/store/payment-splitting/type';
import { ListItem } from '../../../../../../shared/store/typeahead/types';

@UntilDestroy()
@Component({
  selector: 'app-contract-form-payment-splitting',
  templateUrl: './contract-form-payment-splitting.component.html',
  styleUrls: ['./contract-form-payment-splitting.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormPaymentSplittingComponent implements OnInit {
  form: FormGroup;
  paymentSplittingOptions$: Observable<PaymentSplitting[]>;
  paymentSplittingOptions: PaymentSplitting[];
  paymentSplittingDebit: ListItem[];
  paymentSplittingDebitDayOfMonth: ListItem[];
  paymentSplittingNonDebit: ListItem[];
  debit: boolean = true;
  readonly: boolean = false;

  contractDetail$: Observable<ContractModel>;
  paymentSplittingDetail$: Observable<PaymentSplitting>;
  contact$: Observable<ContactModel>;

  customPatterns = {
    0: {
      pattern: new RegExp(/[0-9]/),
    },
    C: {
      pattern: new RegExp('[a-zA-Z0-9]'),
    },
    S: {
      pattern: new RegExp('[a-zA-Z]'),
    },
  };

  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly paymentSplittingService: PaymentSplittingService,
    private readonly contractService: ContractService,
    private readonly contactService: ContactService
  ) {
    this.paymentSplittingOptions$ =
      this.paymentSplittingService.getByProductLine.value$;
    this.contractDetail$ = this.contractService.getByUniqueId.value$;
    this.paymentSplittingDetail$ = this.paymentSplittingService.getById.value$;
    this.contact$ = this.contactService.getById.value$;
  }

  ngOnInit(): void {
    this.debit = true;

    this.form = this.parent.form;
    this.form.addControl(
      'paymentSplittingFormGroup',
      this.formBuilder.group({
        paymentMode: [undefined],
        paymentDebitDay: [undefined],
        paymentSplittingId: [1],
        paymentBankAccount: this.formBuilder.group<BankAccountEdit>({
          id: [0],
          bankId: [undefined, [Validators.required]],
          iban: ['', [Validators.required]],
          bic: ['', [Validators.required]],
          holderName: ['', [Validators.required]],
        }),
        refundingBankAccount: this.formBuilder.group<BankAccountEdit>({
          id: [0],
          bankId: [undefined],
          iban: [''],
          bic: [''],
          holderName: [''],
        }),
        // paymentHolderName: ['', [Validators.required]],
        // paymentIBAN: ['', [Validators.required]],
        // paymentBIC: ['', [Validators.required]],
        // refundingHolderName: [''],
        // refundingIBAN: [''],
        // refundingBIC: [''],
        sameBankAccount: [true],
        paymentDay: [''],
        paymentModeLoc: [''],
      })
    );

    this.contractDetail$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.paymentSplittingService.getById.call(
          x.paymentSplittingId.toString()
        );
        this.paymentSplittingService.getByProductLine.call(x.productLineKey);
        this.readonly = true;
        this.form.patchValue({
          paymentSplittingFormGroup: {
            paymentSplittingId: x.paymentSplittingId,
            sameBankAccount:
              x.refundingBankAccountId === x.paymentBankAccountId,
          },
        });
        if (x.paymentBankAccount) {
          this.form.patchValue({
            paymentSplittingFormGroup: {
              paymentBankAccount: {
                id: x.paymentBankAccountId,
                bankId: x.paymentBankAccount.bankId,
                iban: x.paymentBankAccount.iban,
                bic: x.paymentBankAccount.bic,
                holderName: x.paymentBankAccount.holderName,
              },
            },
          });
        }
        if (x.refundingBankAccount) {
          this.form.patchValue({
            paymentSplittingFormGroup: {
              refundingBankAccount: {
                id: x.refundingBankAccountId,
                bankId: x.refundingBankAccount.bankId,
                iban: x.refundingBankAccount.iban,
                bic: x.refundingBankAccount.bic,
                holderName: x.refundingBankAccount.holderName,
              },
            },
          });
        }

        removeValidators(
          this.form.get(['paymentSplittingFormGroup', 'paymentMode'] as const),
          Validators.required
        );
        removeValidators(
          this.form.get([
            'paymentSplittingFormGroup',
            'paymentDebitDay',
          ] as const),
          Validators.required
        );
      });

    this.paymentSplittingDetail$
      .pipe(
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        // this.onChangePaymentMode(x.splittingModeId.toString());
        this.form.patchValue({
          paymentSplittingFormGroup: {
            paymentMode: x.splittingModeId,
            paymentDebitDay: x.id,
            paymentModeLoc: x.splittingModeLoc,
            paymentDay: x.dayOfMonth,
          },
        });
      });

    this.contact$.pipe(untilDestroyed(this)).subscribe((x) => {
      this.form.patchValue({
        paymentSplittingFormGroup: {
          paymentBankAccount: {
            holderName: x ? x.name : '',
          },
        },
      });
    });

    this.form.controls['productLineKey'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.paymentSplittingService.getByProductLine.reset();
        this.onChangePaymentType(this.debit);
        this.paymentSplittingService.getByProductLine.call(
          this.form.controls['productLineKey'].value
        );
      });

    this.paymentSplittingOptions$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x) {
        this.paymentSplittingNonDebit = x
          .filter((paymentSplitting) => !paymentSplitting.debit)
          .map(
            (result) =>
              <ListItem>{
                id: result.splittingModeId,
                name: result.splittingModeLoc,
              }
          );

        this.paymentSplittingDebit = x
          .filter((paymentSplitting) => paymentSplitting.debit)
          .map(
            (result) =>
              <ListItem>{
                id: result.splittingModeId,
                name: result.splittingModeLoc,
              }
          );
        this.paymentSplittingOptions = x;
      } else {
        this.paymentSplittingNonDebit = [];
        this.paymentSplittingDebit = [];
        this.paymentSplittingOptions = [];
        this.paymentSplittingDebitDayOfMonth = [];
      }
    });
  }

  onChangePaymentMode(value: string) {
    // if (!this.paymentSplittingOptions) return;
    this.paymentSplittingDebitDayOfMonth = this.paymentSplittingOptions
      .filter((x) => x.debit === this.debit && x.splittingModeId === +value)
      .map(
        (result) =>
          <ListItem>{ id: result.id, name: result.dayOfMonth.toString() }
      );
    if (this.paymentSplittingDebitDayOfMonth.length === 1)
      this.form
        .get(['paymentSplittingFormGroup', 'paymentDebitDay'] as const)
        ?.setValue(this.paymentSplittingDebitDayOfMonth[0].id);

    if (!this.debit) {
      this.form
        .get(['paymentSplittingFormGroup', 'paymentSplittingId'] as const)
        ?.setValue(this.paymentSplittingDebitDayOfMonth[0].id);
    }
  }

  onChangePaymentType(checked: boolean) {
    this.debit = checked;

    //reinit
    this.form
      .get(['paymentSplittingFormGroup', 'paymentMode'] as const)
      ?.reset();
    this.form
      .get(['paymentSplittingFormGroup', 'paymentDebitDay'] as const)
      ?.reset();
    this.form
      .get(['paymentSplittingFormGroup', 'paymentSplittingId'] as const)
      ?.reset();
    this.paymentSplittingDebitDayOfMonth = [];

    if (checked) {
      setValidators(
        this.form.get([
          'paymentSplittingFormGroup',
          'paymentDebitDay',
        ] as const),
        Validators.required
      );
      return;
    }
    removeValidators(
      this.form.get(['paymentSplittingFormGroup', 'paymentDebitDay'] as const),
      Validators.required
    );
  }

  onChangeSetRefundingValidators(checked: boolean) {
    // as const gives improved typingsquired,
    //https://v17.angular.io/api/forms/AbstractControl#get
    if (checked) {
      removeValidators(
        this.form.get([
          'paymentSplittingFormGroup',
          'refundingBankAccount',
          'bankId',
        ] as const),
        Validators.required
      );
      removeValidators(
        this.form.get([
          'paymentSplittingFormGroup',
          'refundingBankAccount',
          'holderName',
        ] as const),
        Validators.required
      );
      removeValidators(
        this.form.get([
          'paymentSplittingFormGroup',
          'refundingBankAccount',
          'iban',
        ] as const),
        Validators.required
      );
      removeValidators(
        this.form.get([
          'paymentSplittingFormGroup',
          'refundingBankAccount',
          'bic',
        ] as const),
        Validators.required
      );
      return;
    }

    setValidators(
      this.form.get([
        'paymentSplittingFormGroup',
        'refundingBankAccount',
        'bankId',
      ] as const),
      Validators.required
    );
    setValidators(
      this.form.get([
        'paymentSplittingFormGroup',
        'refundingBankAccount',
        'holderName',
      ] as const),
      Validators.required
    );
    setValidators(
      this.form.get([
        'paymentSplittingFormGroup',
        'refundingBankAccount',
        'iban',
      ] as const),
      Validators.required
    );
    setValidators(
      this.form.get([
        'paymentSplittingFormGroup',
        'refundingBankAccount',
        'bic',
      ] as const),
      Validators.required
    );
  }

  onChangeDebitDay(value: string) {
    this.form
      .get(['paymentSplittingFormGroup', 'paymentSplittingId'] as const)
      ?.setValue(value);
  }
}
