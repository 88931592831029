<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
<!--  *ngIf="this.carDetail$ | async as carDetail"-->
<!--  <div class="d-flex flex-column h-100">-->
<!--    <div class="row mt-2">-->
<!--      <div class="col-12 col-md">-->
<!--        <div class="form-group row">-->
<!--          <label class="col-4 col-form-label-sm" translate-->
<!--          >CONTRACT_LBL_COMMISSION_COMMISSION_RATE</label-->
<!--          >-->
<!--          <div class="col-auto">-->
<!--            <span class="form-control-plaintext form-control-sm">-->
<!--              30%-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group row">-->
<!--          <label class="col-4 col-form-label-sm" translate-->
<!--          >CONTRACT_LBL_COMMISSION_COMMISSION_FIRST_YEAR</label-->
<!--          >-->
<!--          <div class="col-auto">-->
<!--            <span class="form-control-plaintext form-control-sm">-->
<!--              300,00€-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="form-group row">-->
<!--          <label class="col-4 col-form-label-sm" translate-->
<!--          >CONTRACT_LBL_COMMISSION_COMMISSION_COMMISSION_SHARE</label-->
<!--          >-->
<!--          <div class="col-auto">-->
<!--            <span class="form-control-plaintext form-control-sm">-->
<!--              25%-->
<!--            </span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col"></div>-->
<!--    </div>-->
<!--  </div>-->
</div>
