import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  CarReferenceExactModelAction,
  carReferenceExactModelActions,
} from './car-reference-exact-model.action';
import { CarReferenceExactModelApiService } from './car-reference-exact-model.api.service';

@Injectable()
export class CarReferenceExactModelEffects {
  constructor(
    private readonly carReferenceExactModelApiService: CarReferenceExactModelApiService,
    private readonly action$: Actions<CarReferenceExactModelAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    carReferenceExactModelActions.getById,
    this.carReferenceExactModelApiService.getById,
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    carReferenceExactModelActions.createInstance,
    this.carReferenceExactModelApiService.createInstance,
  );
  add = createApiCallEffects(
    this,
    this.action$,
    carReferenceExactModelActions.add,
    this.carReferenceExactModelApiService.add,
  );
  update = createApiCallEffects(
    this,
    this.action$,
    carReferenceExactModelActions.update,
    this.carReferenceExactModelApiService.update,
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    carReferenceExactModelActions.delete,
    this.carReferenceExactModelApiService.delete,
  );
}
