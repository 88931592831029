import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { Entity } from 'src/app/shared/store/view/types';
import {
  DataType,
  DropdownStyle,
} from '../../../../../../shared/store/typeahead/types';
import { distinctUntilChanged, Observable } from 'rxjs';
import {
  removeValidators,
  setValidators,
} from '../../../../../../shared/helper/formhelper';
import { CarInsuranceContractModel } from '../../../../../../shared/store/contract/types';
import { filter, take } from 'rxjs/operators';
import { formatDate } from '../../../../../../shared/helper/datehelper';

@Component({
  selector: 'app-contract-form-vehicle',
  templateUrl: './contract-form-vehicle.component.html',
  styleUrls: ['./contract-form-vehicle.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormVehicleComponent implements OnInit {
  carDetail$: Observable<CarInsuranceContractModel>;
  carInsuranceContractModel$: Observable<CarInsuranceContractModel>;
  form: FormGroup;

  protected readonly Entity = Entity;
  protected readonly DataType = DataType;
  protected readonly DropdownStyle = DropdownStyle;

  isRegistrationHolderNameVisible = false;
  isRegistrationHolderCompanyVisible = false;

  constructor(
    private readonly contractService: ContractService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.carDetail$ = this.contractService.getCarDetail.value$;
    this.carInsuranceContractModel$ =
      this.contractService.getCarContractModel.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'vehicleFormGroup',
      this.formBuilder.group({
        vehicleRegistrationHolder: [undefined],
        vehicleRegistrationHolderCompanyName: [''],
        vehicleRegistrationHolderNameLastName: [''],
        vehicleRegistrationHolderNameFirstName: [''],
        vehicleRegistrationHolderBirthdate: [undefined],
        vehicleIsDriverVehiculeRegistrationHolder: [true],
        vehicleLicensePlateNumber: [''],
        vehicleBrand: [undefined, [Validators.required]],
        vehicleModel: [undefined, [Validators.required]],
        vehicleExactModel: [undefined],
        vehicleCarBody: [undefined, [Validators.required]],
        vehicleCarEnergy: [undefined, [Validators.required]],
        vehicleCarPower: [''],
        vehicleUsage: [undefined],
        vehicleUsageProSupp: [undefined],
        vehicleParkingType: [undefined],
        vehicleDateOfCirculation: [undefined, [Validators.required]],
      }),
    );

    this.carInsuranceContractModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          vehicleFormGroup: {
            vehicleRegistrationHolder: x.vehiculeRegistrationHolder,
            vehicleRegistrationHolderCompanyName:
              x.vehiculeRegistrationHolderCompanyName,
            vehicleRegistrationHolderNameLastName:
              x.vehiculeRegistrationHolderNameLastName,
            vehicleRegistrationHolderNameFirstName:
              x.vehiculeRegistrationHolderNameFirstName,
            vehicleRegistrationHolderBirthdate: formatDate(
              x.vehiculeRegistrationHolderBirthdate,
              'ToInverseSqlDate',
            ),
            vehicleLicensePlateNumber: x.licensePlateNumber,
            vehicleBrand: x.brand,
            vehicleModel: x.model,
            vehicleExactModel: x.exactModel,
            vehicleCarBody: x.carBody,
            vehicleCarEnergy: x.carEnergie,
            vehicleCarPower: x.carPower,
            vehicleUsage: x.vehiculeUsage,
            vehicleUsageProSupp: x.vehiculeUsageProSupp,
            vehicleParkingType: x.parkingType,
            vehicleDateOfCirculation: formatDate(
              x.dateOfCirculation,
              'ToInverseSqlDate',
            ),
          },
        });
      });

    this.carDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          vehicleFormGroup: {
            vehicleRegistrationHolder: x.vehiculeRegistrationHolder,
            vehicleRegistrationHolderCompanyName:
              x.vehiculeRegistrationHolderCompanyName,
            vehicleRegistrationHolderNameLastName:
              x.vehiculeRegistrationHolderNameLastName,
            vehicleRegistrationHolderNameFirstName:
              x.vehiculeRegistrationHolderNameFirstName,
            vehicleRegistrationHolderBirthdate: formatDate(
              x.vehiculeRegistrationHolderBirthdate,
              'ToInverseSqlDate',
            ),
            vehicleLicensePlateNumber: x.licensePlateNumber,
            vehicleBrand: x.brand,
            vehicleModel: x.model,
            vehicleExactModel: x.exactModel,
            vehicleCarBody: x.carBody,
            vehicleCarEnergy: x.carEnergie,
            vehicleCarPower: x.carPower,
            vehicleUsage: x.vehiculeUsage,
            vehicleUsageProSupp: x.vehiculeUsageProSupp,
            vehicleParkingType: x.parkingType,
            vehicleDateOfCirculation: formatDate(
              x.dateOfCirculation,
              'ToInverseSqlDate',
            ),
          },
        });
      });

    this.form.controls['vehicleFormGroup']
      .get('vehicleUsage')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((x) => {
        switch (x) {
          case 'privepro':
            setValidators(
              this.form.controls['vehicleFormGroup'].get('vehicleUsageProSupp'),
              Validators.required,
            );
            break;
          default:
            removeValidators(
              this.form.controls['vehicleFormGroup'].get('vehicleUsageProSupp'),
              Validators.required,
            );
            break;
        }
      });

    this.form.controls['vehicleFormGroup']
      .get('vehicleRegistrationHolder')
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe((x) => {
        switch (x) {
          case 'Société':
          case 'Leasing':
            setValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderCompanyName',
              ),
              Validators.required,
            );
            this.isRegistrationHolderCompanyVisible = true;

            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderNameLastName',
              ),
              Validators.required,
            );
            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderNameFirstName',
              ),
              Validators.required,
            );
            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderBirthdate',
              ),
              Validators.required,
            );
            this.isRegistrationHolderNameVisible = false;

            break;
          case 'Conjoint':
          case 'Concubin':
          case 'Père ou mère':
          case 'Enfant':
          case 'Autre':
            setValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderNameLastName',
              ),
              Validators.required,
            );
            setValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderNameFirstName',
              ),
              Validators.required,
            );
            setValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderBirthdate',
              ),
              Validators.required,
            );
            this.isRegistrationHolderNameVisible = true;

            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderCompanyName',
              ),
              Validators.required,
            );
            this.isRegistrationHolderCompanyVisible = false;
            break;
          default:
            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderNameLastName',
              ),
              Validators.required,
            );
            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderNameFirstName',
              ),
              Validators.required,
            );
            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderBirthdate',
              ),
              Validators.required,
            );
            this.isRegistrationHolderNameVisible = false;

            removeValidators(
              this.form.controls['vehicleFormGroup'].get(
                'vehicleRegistrationHolderCompanyName',
              ),
              Validators.required,
            );
            this.isRegistrationHolderCompanyVisible = false;
            break;
        }
      });
  }

  onChangeSetVehicleRegistrationValidators(checked: boolean) {
    if (checked) {
      removeValidators(
        this.form.controls['vehicleFormGroup'].get('vehicleRegistrationHolder'),
        Validators.required,
      );
      removeValidators(
        this.form.controls['vehicleFormGroup'].get(
          'vehicleRegistrationHolderNameLastName',
        ),
        Validators.required,
      );
      removeValidators(
        this.form.controls['vehicleFormGroup'].get(
          'vehicleRegistrationHolderNameFirstName',
        ),
        Validators.required,
      );
      removeValidators(
        this.form.controls['vehicleFormGroup'].get(
          'vehicleRegistrationHolderBirthdate',
        ),
        Validators.required,
      );
      removeValidators(
        this.form.controls['vehicleFormGroup'].get(
          'vehicleRegistrationHolderCompanyName',
        ),
        Validators.required,
      );
      return;
    }

    setValidators(
      this.form.controls['vehicleFormGroup'].get('vehicleRegistrationHolder'),
      Validators.required,
    );
  }
}
