import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../../common/store/generic.effects';
import { CommissionAction, commissionActions } from './commission.actions';
import { CommissionApiService } from './commission.api.service';

@Injectable()
export class CommissionEffects {
  constructor(
    private readonly commissionApiService: CommissionApiService,
    private readonly action$: Actions<CommissionAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    commissionActions.getById,
    this.commissionApiService.getById,
  );
}
