<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="ps-3">
          <button
            type="button"
            [class]="ButtonType.Primary | buttontypepipe"
            class="btn-sm btn"
            translate
            (click)="addNewQuoteOcealiz()"
          >
            LEAD_LBL_QUOTES_SCRAPER_ADD
          </button>
        </div>
      </div>
    </div>



  <ng-container *ngIf="this.requestStories$ | async as requestStories">
    <ng-container *ngIf="requestStories.length == 0">
     {{ 'LEAD_LBL_QUOTES_SCRAPER_NONE' | translate}}
    </ng-container>
    <ng-container *ngIf="requestStories.length > 0">

      <div class="row mt-2">
        <div class="col">
          <table class="table table-striped border-bottom">
            <thead>
            <tr>
              <th>
                <a translate>LEAD_LBL_QUOTES_SCRAPER_COMPANY</a>
              </th>
              <th>
                <a translate>LEAD_LBL_QUOTES_SCRAPER_RESULT</a>
              </th>
              <th>
                <a translate>LEAD_LBL_QUOTES_SCRAPER_AMOUNT</a>
              </th>
              <th>
                <a translate>LEAD_LBL_QUOTES_SCRAPER_DATE_CREATION</a>
              </th>
              <th>
                <a translate>LEAD_LBL_QUOTES_SCRAPER_ACTION</a>
              </th>
            </tr>
            </thead>

            <tbody>
              <tr *ngFor="let requestStory of requestStories;">
                <td>{{ requestStory.insurance.label }}</td>
                <td>{{ requestStory.errors | arrayString }}</td>
                <td>
                  <ng-container *ngIf="requestStory.monthlyPremium">
                    {{ requestStory.monthlyPremium | currency : "EUR" }}
                  </ng-container>
                  <ng-container *ngIf="!requestStory.monthlyPremium">
                    {{ " - " }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="requestStory.proformaCreationDate">
                    {{ requestStory.proformaCreationDate | date : "dd/MM/yyyy" }}
                  </ng-container>
                  <ng-container *ngIf="!requestStory.proformaCreationDate">
                    {{ " - " }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="requestStory.pdfLink">
                    <a role="button" target="_blank" [href]="requestStory.pdfLink" translate>LEAD_LBL_QUOTES_SCRAPER_PDF_LINK</a>
                  </ng-container>
                  <ng-container *ngIf="!requestStory.pdfLink">
                    {{ " - " }}
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>

  </ng-container>
<!--  <button type="button" (click)="onClickRefreshRequestStories()">Refresh</button>-->
</div>
</div>
