import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CounselorModel } from '../../../../../../../shared/store/scraper/types';
import { ActivatedRoute } from '@angular/router';
import { ScraperService } from '../../../../../../../shared/store/scraper/scraper.service';
import { ModalService } from '../../../../../../../shared/service/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubHeaderService } from '../../../../../../../shared/store/subheader/subheader.service';
import { ButtonType } from 'src/app/shared/store/subheader/type';
import { ModalContentComponent } from '../../../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../../../shared/components/modal-content/action-button';
import { SourceEnum } from '../../../../../../../shared/store/typeahead/types';
import { filterTrue } from '../../../../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-user-details-external-account',
  templateUrl: './user-details-external-account.component.html',
  styleUrls: ['./user-details-external-account.component.scss'],
})
export class UserDetailsExternalAccountComponent implements OnInit, OnDestroy {
  counselors$: Observable<CounselorModel[]>;
  userId: number;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly scraperService: ScraperService,
    private readonly modalExchangeService: ModalService,
    private readonly modalService: NgbModal,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.counselors$ = this.scraperService.getCounselorsByUserId.value$;
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];

    this.scraperService.getCounselorsByUserId.call(this.userId.toString());
  }

  ngOnDestroy(): void {
    this.scraperService.getCounselorsByUserId.reset();
    // this.scraperService.deleteCounselor.reset();
    // this.scraperService.updatePasswordCounselor.reset();
  }

  triggerAction(name: string, id: number) {
    switch (name) {
      case 'counselorUpdatePassword':
        this.openModalUpdatePassword(id);
        break;
      case 'counselorDelete':
        this.onDelete(id);
        break;
    }
  }

  openModalAddCounselor() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'UserExternalAccountCreateContentComponent',
      );

    modalRef.componentInstance.title = 'USER_LBL_EXTERNAL_ACCOUNT_ADD_TITLE';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'counselorCreate',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.scraperService.getCounselorsByUserId.call(
            this.userId.toString(),
          );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  private openModalUpdatePassword(id: number) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'UpdatePasswordAccountComponent',
      );

    modalRef.componentInstance.title =
      'USER_LBL_EXTERNAL_ACCOUNT_PASSWORD_UPDATE_TITLE';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'counselorUpdatePassword',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      id: id,
      ocealizType: 'Counselor',
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          // this.scraperService.getInsurancesBySalesTeamId.call(
          //   this.salesTeamId.toString(),
          // );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  private onDelete(id: number) {
    let result$ = this.scraperService.deleteCounselor.call(id.toString());

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.scraperService.getCounselorsByUserId.call(this.userId.toString());
      this.scraperService.deleteCounselor.reset();
    });
  }
}
