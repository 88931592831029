import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, filter, Observable, of } from 'rxjs';
import { ContactFormNotesComponent } from '../contact-form-tabs/contact-form-notes/contact-form-notes.component';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { FormService } from '../../../../../shared/store/form/form.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContactService } from '../../../../../shared/store/contact/contact.service';
import { ActivatedRoute } from '@angular/router';
import { ContactModel } from '../../../../../shared/store/contact/types';
import { TabsContentSalesTeam } from '../../../../../shared/models/tabs-content-sales-team';

@UntilDestroy()
@Component({
  selector: 'app-contact-tabs-edit-form',
  templateUrl: './contact-tabs-edit-form.component.html',
  styleUrls: ['./contact-tabs-edit-form.component.scss'],
})
export class ContactTabsEditFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;
  contactDetails$: Observable<ContactModel>;
  active = 1;

  tabsContent$: BehaviorSubject<TabsContentContact[]> = new BehaviorSubject<
    TabsContentContact[]
  >([]);

  tabsInit: TabsContentContact[] = [
    // {
    //   title: 'CONTACT_TABS_NOTES',
    //   index: 1,
    //   name: 'notes',
    //   component: ContactFormNotesComponent,
    //   formGroupName: 'notesFormGroup',
    // },
  ];
  constructor(
    private readonly formService: FormService,
    private readonly contactService: ContactService,
  ) {
    this.contactDetails$ = this.contactService.getById.value$;
  }

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.tabsContent$.next(this.tabsInit);

    this.contactDetails$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((contactModel) => {
        if (contactModel.isCompany) this.active = 0;
        else this.active = 1;
      });

    this.form.controls['isCompany'].valueChanges.subscribe((x) => {
      this.active = x ? 0 : 1;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
