import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarReferenceBrandRoutingModule } from './car-reference-brand-routing.module';
import { CarReferenceBrandCreateComponent } from './car-reference-brand-create/car-reference-brand-create.component';
import { CarReferenceBrandDetailsComponent } from './car-reference-brand-details/car-reference-brand-details.component';
import { CarReferenceBrandEditComponent } from './car-reference-brand-edit/car-reference-brand-edit.component';
import { CarReferenceBrandFormContentComponent } from './car-reference-brand-form-content/car-reference-brand-form-content.component';
import { SharedModule } from '../../../shared/shared.module';
import { CarReferenceBrandTabsDetailsComponent } from './car-reference-brand-details/car-reference-brand-tabs-details/car-reference-brand-tabs-details.component';
import { CarReferenceBrandDetailsModelComponent } from './car-reference-brand-details/car-reference-brand-details-tab/car-reference-brand-details-model/car-reference-brand-details-model.component';
import { CarReferenceBrandDetailsExactmodelComponent } from './car-reference-brand-details/car-reference-brand-details-tab/car-reference-brand-details-exactmodel/car-reference-brand-details-exactmodel.component';

@NgModule({
  declarations: [
    CarReferenceBrandCreateComponent,
    CarReferenceBrandDetailsComponent,
    CarReferenceBrandEditComponent,
    CarReferenceBrandFormContentComponent,
    CarReferenceBrandTabsDetailsComponent,
    CarReferenceBrandDetailsModelComponent,
    CarReferenceBrandDetailsExactmodelComponent,
  ],
  imports: [CommonModule, CarReferenceBrandRoutingModule, SharedModule],
})
export class CarReferenceBrandModule {}
