import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductLineDetailsInsuranceDocumentComponent } from '../product-line-details-tab/product-line-details-insurance-documents/product-line-details-insurance-documents.component';
import { ProductLineDetailsProductComponent } from '../product-line-details-tab/product-line-details-products/product-line-details-products.component';
import { ProductLineDetailsNotesComponent } from '../product-line-details-tab/product-line-details-notes/product-line-details-notes.component';
import { ProductLineDetailsCommissionsComponent } from '../product-line-details-tab/product-line-details-commissions/product-line-details-commissions.component';
import { TabsContentProductLine } from '../../../../../shared/models/tabs-content-product-line';
@Component({
  selector: 'app-product-line-tabs-details',
  templateUrl: './product-line-tabs-details.component.html',
  styleUrls: ['./product-line-tabs-details.component.scss'],
})
export class ProductLineTabsDetailsComponent
  implements OnInit, OnChanges, OnDestroy
{
  active = 1;
  currentActive: number = 1;

  tabsContent$: BehaviorSubject<TabsContentProductLine[]> = new BehaviorSubject<
    TabsContentProductLine[]
  >([]);
  tabsInit: TabsContentProductLine[] = [
    {
      title: 'PRODUCTLINE_TABS_PRODUCTS',
      index: 1,
      name: 'products',
      component: ProductLineDetailsProductComponent,
    },
    {
      title: 'PRODUCTLINE_TABS_INSURANCEDOCUMENTS',
      index: 2,
      name: 'insuranceDocuments',
      component: ProductLineDetailsInsuranceDocumentComponent,
    },
    // {
    //   title: 'PRODUCTLINE_TABS_COMMISSIONS',
    //   index: 3,
    //   name: 'commissions',
    //   component: ProductLineDetailsCommissionsComponent,
    // },
    {
      title: 'PRODUCTLINE_TABS_NOTES',
      index: 4,
      name: 'notes',
      component: ProductLineDetailsNotesComponent,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.tabsContent$.next(this.tabsInit);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.currentActive) {
      this.active = this.currentActive;
    } else {
      this.active = 1;
    }
    this.tabsContent$.next(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentProductLine) {
    return item.index;
  }

  onActiveIdChangeSaveCurrent(active: number) {
    this.currentActive = active;
  }

  ngOnDestroy(): void {
    this.currentActive = 0;
  }
}
