import { Component } from '@angular/core';

@Component({
  selector: 'app-car-reference-exact-model-create',
  templateUrl: './car-reference-exact-model-create.component.html',
  styleUrls: ['./car-reference-exact-model-create.component.scss']
})
export class CarReferenceExactModelCreateComponent {

}
