import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { CounselorModel, InsuranceModel, RequestStoryModel } from './types';
import { scraperActions } from './scraper.actions';
import { scraperSelectors } from './scraper.selector';
import { BindType } from '../accounting/types';

@Injectable()
export class ScraperService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getRequestStoriesByLeadId: GenericApiCall<RequestStoryModel[], string> =
    this.genericApiCall(
      scraperActions.getRequestStoriesByLeadId,
      scraperSelectors.getRequestStoriesByLeadId,
      true,
    );
  scraping: GenericApiCall<
    RequestStoryModel,
    { leadUniqueId: string; ocealizCounselorId: string }
  > = this.genericApiCall(
    scraperActions.scraping,
    scraperSelectors.scraping,
    true,
  );
  getCounselorsByUser: GenericApiCall<CounselorModel[], void> =
    this.genericApiCall(
      scraperActions.getCounselorsByUser,
      scraperSelectors.getCounselorsByUser,
      true,
    );
  getCounselorsByUserId: GenericApiCall<CounselorModel[], string> =
    this.genericApiCall(
      scraperActions.getCounselorsByUserId,
      scraperSelectors.getCounselorsByUserId,
      true,
    );
  addCounselor: GenericApiCall<CounselorModel, CounselorModel> =
    this.genericApiCall(
      scraperActions.addCounselor,
      scraperSelectors.addCounselor,
      true,
    );
  updatePasswordCounselor: GenericApiCall<
    boolean,
    { counselorId: string; password: string }
  > = this.genericApiCall(
    scraperActions.updatePasswordCounselor,
    scraperSelectors.updatePasswordCounselor,
    true,
  );
  deleteCounselor: GenericApiCall<boolean, string> = this.genericApiCall(
    scraperActions.deleteCounselor,
    scraperSelectors.deleteCounselor,
    true,
  );
  getInsurancesBySalesTeamId: GenericApiCall<InsuranceModel[], string> =
    this.genericApiCall(
      scraperActions.getInsurancesBySalesTeamId,
      scraperSelectors.getInsurancesBySalesTeamId,
      true,
    );
  addInsurance: GenericApiCall<InsuranceModel, InsuranceModel> =
    this.genericApiCall(
      scraperActions.addInsurance,
      scraperSelectors.addInsurance,
      true,
    );
  updatePasswordInsurance: GenericApiCall<
    boolean,
    { insuranceId: string; password: string }
  > = this.genericApiCall(
    scraperActions.updatePasswordInsurance,
    scraperSelectors.updatePasswordInsurance,
    true,
  );
  deleteInsurance: GenericApiCall<boolean, string> = this.genericApiCall(
    scraperActions.deleteInsurance,
    scraperSelectors.deleteInsurance,
    true,
  );
}
