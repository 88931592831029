import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { articleActions } from './article.actions';
import { articleSelectors } from './article.selectors';
import { Article, ArticlePost, ArticleUpdate } from './types';

@Injectable()
export class ArticleService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Article, string> = this.genericApiCall(
    articleActions.getById,
    articleSelectors.getById,
    true,
  );
  postArticle: GenericApiCall<any, ArticlePost> = this.genericApiCall(
    articleActions.postArticle,
    articleSelectors.postArticle,
    true,
  );
  publishArticle: GenericApiCall<any, string> = this.genericApiCall(
    articleActions.publishArticle,
    articleSelectors.publishArticle,
    true,
  );
  unpublishArticle: GenericApiCall<any, string> = this.genericApiCall(
    articleActions.unpublishArticle,
    articleSelectors.unpublishArticle,
    true,
  );
  updateArticle: GenericApiCall<any, ArticleUpdate> = this.genericApiCall(
    articleActions.updateArticle,
    articleSelectors.updateArticle,
    true,
  );
  delete: GenericApiCall<boolean, string> = this.genericApiCall(
    articleActions.delete,
    articleSelectors.delete,
    true,
  );
}
