import { createApiCallActions } from '../../../common/store/generic.actions';
import { CounselorModel, InsuranceModel, RequestStoryModel } from './types';

export const scraperActions = {
  getListInsurances: createApiCallActions<InsuranceModel[]>(
    'scraper',
    'getListInsurances',
  ),
  getInsurancesBySalesTeamId: createApiCallActions<InsuranceModel[]>(
    'scraper',
    'getInsurancesBySalesTeamId',
  ),
  addInsurance: createApiCallActions<InsuranceModel>('scraper', 'addInsurance'),
  deleteInsurance: createApiCallActions<boolean>('scraper', 'deleteInsurance'),
  updatePasswordInsurance: createApiCallActions<boolean>(
    'scraper',
    'updatePasswordInsurance',
  ),
  getCounselorsByUser: createApiCallActions<CounselorModel[]>(
    'scraper',
    'getCounselorsByUser',
  ),
  getCounselorsByUserId: createApiCallActions<CounselorModel[]>(
    'scraper',
    'getCounselorsByUserId',
  ),
  addCounselor: createApiCallActions<CounselorModel>('scraper', 'addCounselor'),
  deleteCounselor: createApiCallActions<boolean>('scraper', 'deleteCounselor'),
  updatePasswordCounselor: createApiCallActions<boolean>(
    'scraper',
    'updatePasswordCounselor',
  ),
  scraping: createApiCallActions<RequestStoryModel>('scraper', 'scraping'),
  getRequestStoriesByLeadId: createApiCallActions<RequestStoryModel[]>(
    'scraper',
    'getRequestStoriesByLeadId',
  ),
  getRequestStoryById: createApiCallActions<RequestStoryModel>(
    'scraper',
    'getRequestStoryById',
  ),
};

export type ScraperAction = typeof scraperActions;
