import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayString',
})
export class ArrayStringPipe implements PipeTransform {
  transform(value: string[]): string {
    return value.join(',');
  }
}
