import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-link-to',
  templateUrl: './link-to.component.html',
  styleUrls: ['./link-to.component.scss'],
})
export class LinkToComponent {
  @Input() label: string;
  @Input() url: string;
  @Input() id: string;
}
