<app-document-subheader />
<app-custom-actions-flow-contract />
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div
        class="page-container"
        *ngIf="this.contractBase$ | async as contract"
      >
        <div class="card card-light w-100">
          <app-smart-actions [smartActions]="this.smartActions" />
          <div class="card-body">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-12 col-md-9 mr-auto">
                  <div class="row">
                    <div class="col-6">
                      <label class="form-title-label" translate
                        >CONTRACT_LBL_NAME</label
                      >
                      <input
                        class="form-control-plaintext form-title"
                        type="text"
                        readonly
                        value="{{ contract.name }}"
                      />
                    </div>
                    <div class="col-6"></div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_CLIENT</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        <a
                          routerLink="/Contacts/Contacts/Details/{{
                            this.contract.contactId
                          }}"
                        >
                          {{ this.contract.contactName }}
                        </a>
                      </span>

                      <!--                      <input-->
                      <!--                        class="form-control-plaintext form-control-sm"-->
                      <!--                        type="text"-->
                      <!--                        readonly-->
                      <!--                        value="{{ this.contract.contactName }}"-->
                      <!--                      />-->
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_ADDRESS</label
                    >
                    <div class="col">
                      <div
                        class="form-control-plaintext form-control-sm mb-2 h-100 p-0"
                      >
                        <app-address
                          [address]="this.contract.contactAddress"
                          [address2]="this.contract.contactAddress2"
                          [zipCode]="this.contract.contactZipCode"
                          [city]="this.contract.contactCity"
                          [country]="this.contract.contactCountryName"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_SALESTEAM</label
                    >
                    <div class="col">
                      <input
                        class="form-control-plaintext form-control-sm"
                        type="text"
                        readonly
                        value="{{ contract.salesTeamName }}"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_SALESPERSON</label
                    >
                    <div class="col">
                      <input
                        class="form-control-plaintext form-control-sm"
                        type="text"
                        readonly
                        value="{{ contract.salesPersonContactName }}"
                      />
                    </div>
                  </div>

<!--                  <div class="form-group row">-->
<!--                    <label class="col-4 col-form-label-sm" translate-->
<!--                      >Collaborateur commercial</label-->
<!--                    >-->
<!--                    <div class="col">-->
<!--                      <input-->
<!--                        class="form-control-plaintext form-control-sm"-->
<!--                        type="text"-->
<!--                        readonly-->
<!--                        value="BINON Juliette"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_MANAGER</label
                    >
                    <div class="col">
                      <input
                        class="form-control-plaintext form-control-sm"
                        type="text"
                        readonly
                        value="{{ contract.contractManagerContactName }}"
                      />
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_CATEGORY</label
                    >
                    <div class="col">
                      <div
                        class="form-control-plaintext form-control-sm badge-list"
                      >
                        <app-badge
                          [message]="contract.productCategoryName"
                          [color]="contract.categoryColor"
                        ></app-badge>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_COMPANY</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ contract.companyKey }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_PRODUCTLINE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ contract.productLineKey }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_PRODUCT</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ contract.productKey }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_EFFECT_DATE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ contract.startDate | date : "dd/MM/yyyy" }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_SUBSCRIPTION_DATE</label
                    >
                    <div class="col">
                      <input
                        class="form-control-plaintext form-control-sm"
                        type="text"
                        readonly
                        [value]="
                          contract.subscriptionDate
                            ? (contract.subscriptionDate | date : 'dd/MM/yyyy')
                            : '-'
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <app-contract-tabs-details
                [category]="contract.productCategoryProductCategoryEnumKey"
                [contractBase]="contract"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.contractBase$ | async as contract">
      <app-history-panel
        [entityType]="'Contract'"
        [entityId]="contract.id.toString()"
      ></app-history-panel>
    </div>
  </div>
</div>
