import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CommissionPlanRuleApiService } from './commission-plan-rule.api.service';
import { CommissionPlanRuleEffects } from './commission-plan-rule.effects';
import {
  ICommissionPlanRuleState,
  commissionPlanRuleInitialState,
  commissionPlanRuleReducer,
} from './commission-plan-rule.reducer';
import { CommissionPlanRuleService } from './commission-plan-rule.service';
import { commissionPlanRuleFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ICommissionPlanRuleState>(
      commissionPlanRuleFeatureKey,
      commissionPlanRuleReducer,
      {
        initialState: commissionPlanRuleInitialState,
      },
    ),
    EffectsModule.forFeature([CommissionPlanRuleEffects]),
  ],
  providers: [CommissionPlanRuleApiService, CommissionPlanRuleService],
})
export class StoreCommissionPlanRuleModule {}
