import { createApiCallActions } from '../../../common/store/generic.actions';
import { CarReferenceModelModel } from './types';

export const carReferenceModelActions = {
  getById: createApiCallActions<CarReferenceModelModel>(
    'carReferenceModel',
    'getById',
  ),
  createInstance: createApiCallActions<CarReferenceModelModel>(
    'carReferenceModel',
    'createInstance',
  ),
  add: createApiCallActions<CarReferenceModelModel>('carReferenceModel', 'add'),
  update: createApiCallActions<CarReferenceModelModel>(
    'carReferenceModel',
    'update',
  ),
  delete: createApiCallActions<boolean>('carReferenceModel', 'delete'),
};

export type CarReferenceModelAction = typeof carReferenceModelActions;
