<form [formGroup]="carReferenceExactModelForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="carReferenceExactModelForm">
  <button type="submit" hidden="hidden"></button>
  <div class="row mb-2">
    <div class="col-6 mr-auto">
      <div class="form-group">
        <label class="form-title-label" for="name" translate>CAR_REFERENCE_EXACT_MODEL_LBL_NAME</label>
        <input
          class="form-control form-title"
          id="name"
          name="name"
          [placeholder]="''"
          type="text"
          value=""
          formControlName="name"
          oninput="this.value = this.value.toUpperCase()"
          checkFormFieldValidity
        />
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="carReferenceBrandId" translate
        >CAR_REFERENCE_EXACT_MODEL_LBL_BRAND</label
        >
        <div class="col">
          <app-typeahead
            id="carReferenceBrandId"
            [routeEndpoint]="'CarReferenceBrands'"
            [controlForm]="this.carReferenceExactModelForm.get('carReferenceBrandId')"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [orderBy]="'name asc'"
            [limitToList]="true"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-4 col-form-label-sm" for="carReferenceModelId" translate
        >CAR_REFERENCE_EXACT_MODEL_LBL_MODEL</label
        >
        <div class="col">
          <app-typeahead
            id="carReferenceModelId"
            [routeEndpoint]="'CarReferenceModels'"
            [controlForm]="this.carReferenceExactModelForm.get('carReferenceModelId')"
            [referenceFilterParentName]="'CarReferenceBrandId'"
            [referenceFilterParentValue]="
                            this.carReferenceExactModelForm.controls['carReferenceBrandId'].value
                          "
            [displayMember]="'name'"
            [valueMember]="'id'"
            [orderBy]="'name asc'"
            [limitToList]="true"
            [domain]="this.modelDomain"
          />
        </div>
      </div>
    </div>
  </div>
</form>
