<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="articleForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="articleForm">
      <button type="submit" hidden="hidden"></button>
      <div class="card-body">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-12 col-md-9 mr-auto">
              <div class="form-group">
                <div class="col-12 px-0">
                  <input
                    class="form-control form-control-sm px-0 form-title"
                    placeholder="{{ 'ARTICLE_PLACEHOLDER_NAME' | translate }}"
                    id="name"
                    name="name"
                    type="name"
                    value=""
                    [maxLength]="80"
                    formControlName="name"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md">
              <div class="form-group row">
                <label
                  class="col-3 col-form-label-sm control-label"
                  for="url"
                  translate
                >ARTICLE_LBL_URL</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    [placeholder]="'ARTICLE_PLACEHOLDER_URL' | translate"
                    id="url"
                    name="url"
                    type="text"
                    value=""
                    formControlName="url"
                    checkFormFieldValidity
                  />
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-3 col-form-label-sm control-label"
                  for="description"
                  translate
                >ARTICLE_LBL_DESCRIPTION</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    placeholder="{{ 'ARTICLE_PLACEHOLDER_DESCRIPTION' | translate }}"
                    id="description"
                    name="description"
                    type="text"
                    value=""
                    formControlName="description"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
          <div>
            <div class="form-group">
              <label class="form-title-label" translate
              >ARTICLE_LBL_PAGECONTENT</label
              >  (<a role='button' (click)="onClickShowHtml()" translate *ngIf="this.isVisible">ARTICLE_LBL_HTML_HIDE</a><a role='button' (click)="onClickShowHtml()" translate *ngIf="!this.isVisible">ARTICLE_LBL_HTML_SHOW</a>)
              <div class="col-8 px-0">
                <div class="NgxEditor__Wrapper">
                  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
                  <ngx-editor
                    [editor]="editor"
                    formControlName="pageContent"
                    [disabled]="false"
                    [placeholder]="'ARTICLE_PLACEHOLDER_CONTENT' | translate"
                  ></ngx-editor>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="this.isVisible">
              <label class="form-title-label" translate
              >ARTICLE_LBL_HTML</label>  (<a role='button' class (click)="onClickImportHtmlToEditor()" translate>ARTICLE_LBL_HTML_IMPORT</a>)
              <div class="col-8 px-0">
                <textarea
                  formControlName="htmlArea"
                  [placeholder]="''"
                  class="form-control form-control-sm height-size"
                >
                </textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md">
              <div class="form-group row">
                <label class="col-3 col-form-label-sm" translate
                >ARTICLE_LBL_THUMBNAIL</label
                >
                <div class="col">
                  <app-generic-file-input [acceptFiles]="'.jpg, .jpeg, .png, .webp'"
                                          (fileEmitter)="onFileEmitterThumbnail($event)"/>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3 col-form-label-sm" translate
                >ARTICLE_LBL_HEADER</label
                >
                <div class="col">
                  <app-generic-file-input [acceptFiles]="'.jpg, .jpeg, .png, .webp'"
                                          (fileEmitter)="onFileEmitterHeader($event)"/>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3 col-form-label-sm" translate
                >ARTICLE_LBL_IMAGE</label
                >
                <div class="col">
                  <app-generic-file-input [acceptFiles]="'.jpg, .jpeg, .png, .webp'"
                                          (fileEmitter)="onFileEmitterImageContent($event)"/>
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
