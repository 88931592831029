<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <div
    class="d-flex flex-column h-100"
    *ngIf="this.contractBase$ | async as contractBase"
  >
    <div class="form-group-title my-1" translate>
      CONTRACT_FIELDSET_PAYMENT_SPLITTING_PAYMENT_DETAILS
    </div>
    <div
      class="row mt-2"
      *ngIf="this.paymentSplitting$ | async as paymentSplitting"
    >
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_SPLITTING_MODE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              {{ paymentSplitting.splittingModeLoc }}
            </span>
          </div>
        </div>

        <div class="form-group row" *ngIf="paymentSplitting.debit">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_DEBIT_DAY</label
          >
          <div class="col">
            <span
              class="form-control-plaintext form-control-sm"
              translate
              [translateParams]="{ dayOfMonth: paymentSplitting.dayOfMonth }"
            >
              CONTRACT_MSG_PAYMENT_SPLITTING_DEBIT_DAY
            </span>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_PAYMENT_TYPE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              {{ paymentSplitting.paymentType }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-6 col-md">
        <div class="form-group-title my-1" translate>
          CONTRACT_FIELDSET_PAYMENT_SPLITTING_BANK_DETAILS
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_BANK_NAME</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ this.contractBase.paymentBankAccount?.bankName}}"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_IBAN</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{this.contractBase.paymentBankAccount?.iban }}"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_BIC</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ this.contractBase.paymentBankAccount?.bic}}"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_HOLDER_NAME</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ this.contractBase.paymentBankAccount?.holderName }}"
            />
          </div>
        </div>
      </div>
      <div class="col-6 col-md">
        <div
          class="form-group-title my-1"
          translate
          *ngIf="
            this.contractBase.refundingBankAccountId !=
            this.contractBase.paymentBankAccountId
          "
        >
          CONTRACT_FIELDSET_PAYMENT_SPLITTING_REFUNDING_DETAILS
        </div>
        <div
          class="form-group row"
          *ngIf="
            this.contractBase.refundingBankAccountId !=
            this.contractBase.paymentBankAccountId
          "
        >
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_BANK_NAME</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{this.contractBase.refundingBankAccount?.bankName }}"
            />
          </div>
        </div>
        <div
          class="form-group row"
          *ngIf="
            this.contractBase.refundingBankAccountId !=
            this.contractBase.paymentBankAccountId
          "
        >
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_IBAN</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ this.contractBase.refundingBankAccount?.iban  }}"
            />
          </div>
        </div>
        <div
          class="form-group row"
          *ngIf="
            this.contractBase.refundingBankAccountId !=
            this.contractBase.paymentBankAccountId
          "
        >
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_BIC</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ this.contractBase.refundingBankAccount?.bic }}"
            />
          </div>
        </div>
        <div
          class="form-group row"
          *ngIf="
            this.contractBase.refundingBankAccountId !=
            this.contractBase.paymentBankAccountId
          "
        >
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_PAYMENT_SPLITTING_HOLDER_NAME</label
          >
          <div class="col">
            <input
              class="form-control-plaintext form-control-sm"
              type="text"
              readonly
              value="{{ this.contractBase.refundingBankAccount?.holderName }}"
            />
          </div>
        </div>
      </div>
    </div>

<!--    <div class="row mt-2">-->
<!--      <div class="form-group-title my-1" translate>-->
<!--        CONTRACT_FIELDSET_PAYMENT_SPLITTING_PRIME-->
<!--      </div>-->
<!--      <div-->
<!--        class="row mt-2"-->
<!--      >-->
<!--        <div class="col-12 col-md">-->
<!--          <div class="form-group row">-->
<!--            <label class="col-4 col-form-label-sm" translate-->
<!--            >CONTRACT_LBL_PAYMENT_SPLITTING_PRIME_MONTHLY</label-->
<!--            >-->
<!--            <div class="col">-->
<!--            <span class="form-control-plaintext form-control-sm">-->
<!--              83,33€-->
<!--            </span>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="form-group row">-->
<!--            <label class="col-4 col-form-label-sm" translate-->
<!--            >CONTRACT_LBL_PAYMENT_SPLITTING_PRIME_ANNUALLY</label-->
<!--            >-->
<!--            <div class="col">-->
<!--            <span-->
<!--              class="form-control-plaintext form-control-sm"-->
<!--              translate-->
<!--            >-->
<!--              1000,00€-->
<!--            </span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="col"></div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</div>
