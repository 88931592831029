<form [formGroup]="insuranceForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="insuranceForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">

    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >Compagnie</label
      >
      <div class="col">
        <app-typeahead
          [limitToList]="true"
          [dataType]="DataType.Enum"
          [referenceEndpoint]="'Views/enum/values'"
          [referenceField]="'Comparanoo.Core.Enums.Scrapers.ExternalAccountType'"
          [dropdownStyle]="DropdownStyle.DropDown"
          [controlForm]="this.insuranceForm.controls['externalAccountType']"
          [showFirstElement]="true"
          [valueMember]="'name'"
        ></app-typeahead>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >Label</label
      >
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="text"
          [placeholder]="''"
          formControlName="label"
          checkFormFieldValidity
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >Identifiant</label
      >
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="text"
          [placeholder]="''"
          formControlName="username"
          autocomplete="new-password"
          checkFormFieldValidity
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >Mot de passe</label
      >
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="password"
          [placeholder]="''"
          formControlName="password"
          autocomplete="new-password"
          checkFormFieldValidity
        />
      </div>
    </div>
  </div>
</form>
