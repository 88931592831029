import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-car-reference-model-edit',
  templateUrl: './car-reference-model-edit.component.html',
  styleUrls: ['./car-reference-model-edit.component.scss'],
})
export class CarReferenceModelEditComponent implements OnInit {
  carReferenceModelId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.carReferenceModelId = this.route.snapshot.params['id'];
  }
}
