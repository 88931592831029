import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScraperService } from '../../../../../../shared/store/scraper/scraper.service';
import { Observable } from 'rxjs';
import { InsuranceModel } from '../../../../../../shared/store/scraper/types';
import { ActivatedRoute } from '@angular/router';
import { ButtonType } from 'src/app/shared/store/subheader/type';
import { ModalContentComponent } from '../../../../../../shared/components/modal-content/modal-content.component';
import { ActionButton } from '../../../../../../shared/components/modal-content/action-button';
import { SourceEnum } from '../../../../../../shared/store/typeahead/types';
import { ModalService } from '../../../../../../shared/service/modal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubHeaderService } from '../../../../../../shared/store/subheader/subheader.service';
import { filterTrue } from '../../../../../../shared/pipe/rxjs/operators';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-sales-team-details-external-account',
  templateUrl: './sales-team-details-external-account.component.html',
  styleUrls: ['./sales-team-details-external-account.component.scss'],
})
export class SalesTeamDetailsExternalAccountComponent
  implements OnInit, OnDestroy
{
  insurances$: Observable<InsuranceModel[]>;
  salesTeamId: number;

  protected readonly ButtonType = ButtonType;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly scraperService: ScraperService,
    private readonly modalExchangeService: ModalService,
    private readonly modalService: NgbModal,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.insurances$ = this.scraperService.getInsurancesBySalesTeamId.value$;
  }

  ngOnInit(): void {
    this.salesTeamId = this.route.snapshot.params['id'];

    this.scraperService.getInsurancesBySalesTeamId.call(
      this.salesTeamId.toString(),
    );
  }

  ngOnDestroy(): void {
    this.scraperService.getInsurancesBySalesTeamId.reset();
    this.scraperService.deleteInsurance.reset();
    this.scraperService.updatePasswordInsurance.reset();
  }

  openModalAddInsurance() {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'SalesTeamExternalAccountCreateContentComponent',
      );

    modalRef.componentInstance.title = 'SALES_TEAM_LBL_EXTERNAL_ADD_TITLE';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'insuranceCreate',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          this.scraperService.getInsurancesBySalesTeamId.call(
            this.salesTeamId.toString(),
          );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }

  triggerAction(name: string, id: number) {
    switch (name) {
      case 'insuranceUpdatePassword':
        this.openModalUpdatePassword(id);
        break;
      case 'insuranceDelete':
        this.onDelete(id);
        break;
    }
  }

  private onDelete(id: number) {
    let result$ = this.scraperService.deleteInsurance.call(id.toString());

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.scraperService.getInsurancesBySalesTeamId.call(
        this.salesTeamId.toString(),
      );
      this.scraperService.deleteInsurance.reset();
    });
  }

  private openModalUpdatePassword(id: number) {
    const modalRef = this.modalService.open(ModalContentComponent, {
      size: 'lg',
      backdrop: 'static',
    });

    modalRef.componentInstance.component =
      this.modalExchangeService.getComponentClass(
        'UpdatePasswordAccountComponent',
      );

    modalRef.componentInstance.title =
      'SALES_TEAM_LBL_EXTERNAL_PASSWORD_UPDATE_TITLE';
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.buttons = [
      <ActionButton>{
        actionName: 'insuranceUpdatePassword',
      },
    ];
    modalRef.componentInstance.inputParams = {
      source: SourceEnum.Modal,
      id: id,
      ocealizType: 'Insurance',
    };

    modalRef.result
      .then((id) => {
        if (id >= 0) {
          // this.scraperService.getInsurancesBySalesTeamId.call(
          //   this.salesTeamId.toString(),
          // );
        }
      })
      .finally(() => {
        //avoid error on cancel
      });
  }
}
