import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { CounselorModel, InsuranceModel, RequestStoryModel } from './types';
import { IssuingAccountMovePayingAccountMoveModel } from '../accounting/types';
import { ActivityModel } from '../activity/types';

@Injectable({
  providedIn: 'root',
})
export class ScraperApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getRequestStoriesByLeadId = (
    leadUniqueId: string,
  ): Observable<RequestStoryModel[]> => {
    let parameters: string[];
    parameters = ['quotes', leadUniqueId];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<RequestStoryModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  scraping = ({
    leadUniqueId,
    ocealizCounselorId,
  }: {
    leadUniqueId: string;
    ocealizCounselorId: string;
  }): Observable<RequestStoryModel> => {
    let parameters: string[];
    parameters = ['scraping', leadUniqueId, ocealizCounselorId];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<RequestStoryModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getInsurancesBySalesTeamId = (id: string): Observable<InsuranceModel[]> => {
    let parameters: string[];
    parameters = ['insurances', 'salesteam', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<InsuranceModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  addInsurance = (
    insuranceModel: InsuranceModel,
  ): Observable<InsuranceModel> => {
    let parameters: string[];
    parameters = ['insurances'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<InsuranceModel>(url, insuranceModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  updatePasswordInsurance = ({
    insuranceId,
    password,
  }: {
    insuranceId: string;
    password: string;
  }): Observable<boolean> => {
    let parameters: string[];
    parameters = ['insurances', insuranceId];
    let url = this.formatUrl(parameters);

    let formData = new FormData();
    formData.append('password', password);

    return this.httpClient
      .post<boolean>(url, formData, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  deleteInsurance = (insuranceId: string): Observable<boolean> => {
    let parameters: string[];
    parameters = ['insurances', insuranceId];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .delete<boolean>(url, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getCounselorsByUser = (): Observable<CounselorModel[]> => {
    let parameters: string[];
    parameters = ['counselors', 'user'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CounselorModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getCounselorsByUserId = (userId: string): Observable<CounselorModel[]> => {
    let parameters: string[];
    parameters = ['counselors', 'user', userId];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CounselorModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  addCounselor = (
    counselorModel: CounselorModel,
  ): Observable<CounselorModel> => {
    let parameters: string[];
    parameters = ['counselors'];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<CounselorModel>(url, counselorModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  updatePasswordCounselor = ({
    counselorId,
    password,
  }: {
    counselorId: string;
    password: string;
  }): Observable<boolean> => {
    let parameters: string[];
    parameters = ['counselors', counselorId];
    let url = this.formatUrl(parameters);

    let formData = new FormData();
    formData.append('password', password);

    return this.httpClient
      .post<boolean>(url, formData, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  deleteCounselor = (counselorId: string): Observable<boolean> => {
    let parameters: string[];
    parameters = ['counselors', counselorId];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .delete<boolean>(url, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.scraper.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
