import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CommissionApiService } from "./commission.api.service";
import { CommissionEffects } from "./commission.effects";
import { ICommissionState, commissionInitialState, commissionReducer } from "./commission.reducer";
import { CommissionService } from "./commission.service";
import { commissionFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<ICommissionState>(commissionFeatureKey, commissionReducer,{
            initialState: commissionInitialState,
        }),
        EffectsModule.forFeature([CommissionEffects])
    ],
    providers:[CommissionApiService, CommissionService]
})
export class StoreCommissionModule{}
