<div
  class="tab-pane fade flex-even active show"
  id="insuranceDocuments"
  role="tabpanel"
  aria-labelledby="insuranceDocuments-tab"
  *ngIf="this.productLine$ | async as productLine"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <table
        class="table table-striped border-bottom"
        *ngIf="productLine.productCategoryInsuranceDocuments?.length != 0"
      >
        <thead>
          <tr>
            <th>
              <a translate>INSURANCEDOCUMENT_LBL_NAME</a>
            </th>
            <th class="text-center">
              <a translate>INSURANCEDOCUMENT_LBL_MANDATORY</a>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="
              let insuranceDocument of productLine.productCategoryInsuranceDocuments
            "
            class="row-action"
          >
            <td>{{ insuranceDocument.name }}</td>
            <td class="text-center">
              <input
                type="checkbox"
                [checked]="insuranceDocument.isMandatory"
                disabled
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
