import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SourceEnum } from '../../../store/typeahead/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from '../../../store/form/form.service';
import { AccountingService } from '../../../store/accounting/accounting.service';
import { SlipModel, SlipResponseModel } from '../../../store/accounting/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SubHeaderService } from '../../../store/subheader/subheader.service';
import { take } from 'rxjs/operators';
import { filterTrue } from '../../../pipe/rxjs/operators';
import { ModalService } from '../../../service/modal.service';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Component({
  selector: 'app-slip-add-content',
  templateUrl: './slip-add-content.component.html',
  styleUrls: ['./slip-add-content.component.scss'],
})
export class SlipAddContentComponent implements OnInit, OnDestroy {
  @Input() source: SourceEnum = SourceEnum.Component;
  @Input({ required: true }) slipType: 'Payment' | 'Commission';
  @Input({ required: true }) contactId: number;

  slipForm: FormGroup;
  environmentUrl: string = '';
  slipResponses$: Observable<SlipResponseModel[]>;
  readonly: boolean = false;
  csvTemplateUrl: string = '';

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly formService: FormService,
    private readonly accountingService: AccountingService,
    private readonly subHeaderService: SubHeaderService,
    private readonly dynamicService: ModalService,
    private readonly activeModal: NgbActiveModal,
  ) {
    this.slipResponses$ = this.accountingService.importSlip.value$;
  }

  ngOnInit(): void {
    this.csvTemplateUrl =
      environment.comparanooapiurl + environment.files.csvTemplate.url;
    // debugger;
    this.slipForm = this.formBuilder.group({
      fromAccountId: [undefined, [Validators.required]],
      toAccountId: [undefined, [Validators.required]],
      reference: ['', [Validators.required]],
      file: [undefined, [Validators.required]],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'slipImport') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onFileEmitterPaymentSlip(file: File | undefined) {
    this.slipForm.patchValue({ file: file });
  }

  onSubmit() {
    this.accountingService.importSlip.reset();

    if (this.slipForm.invalid) {
      this.slipForm.markAllAsTouched();
      this.formService.setEntityErrors('SLIP');
      this.formService.countErrors(this.slipForm, true);
      return;
    }

    const slipModel: SlipModel = {
      debitAccountId: this.slipForm.value.fromAccountId!,
      creditAccountId: this.slipForm.value.toAccountId!,
      reference: this.slipForm.value.reference!,
      file: this.slipForm.value.file!,
    };

    let result$ = this.accountingService.importSlip.call(slipModel);

    this.slipForm.patchValue({ file: undefined });
    this.readonly = true;

    result$.pipe(filterTrue(), untilDestroyed(this)).subscribe((result) => {
      this.formService.clear();
      switch (this.source) {
        case SourceEnum.Component:
          // if (result.id == user.contactId) {
          //   this.userService.getCurrentUser.call();
          // }
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
        case SourceEnum.Modal:
          // Vérifie si un élément a le statut 'Error'
          const hasError = result.some(({ status }) => status === 'Error');

          if (!hasError) {
            this.dynamicService.outputFromDynamicComponent('1');
          }

          break;
        default:
          // this.router.navigate(['/Contacts/Contacts/Details', result.id]);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.accountingService.importSlip.reset();
  }
}
