import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../../common/store/types';
import { CommissionModel } from './types';
import { commissionActions } from './commission.actions';

export interface ICommissionState extends IState<CommissionModel> {
  getById: GenericState<CommissionModel>;
}

export const commissionInitialState: ICommissionState = {
  getById: new GenericState<CommissionModel>(),
};

const commissionReducers = [
  ...onApiCall<CommissionModel>(commissionActions.getById, 'getById'),
];

export const commissionReducer = createReducer(
  commissionInitialState,
  ...commissionReducers,
) as ActionReducer<ICommissionState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return commissionReducer(state, action);
}
