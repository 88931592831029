import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../../common/store/generic.selectors';
import { CommissionPlanRuleModel, commissionPlanRuleFeatureKey } from './types';
import { ICommissionPlanRuleState } from './commission-plan-rule.reducer';

const commissionPlanRuleFeatureState =
  createFeatureSelector<ICommissionPlanRuleState>(commissionPlanRuleFeatureKey);

export const commissionPlanRuleSelectors = {
  getById: createApiCallSelectors<number, ICommissionPlanRuleState>(
    commissionPlanRuleFeatureState,
    'getById',
  ),
  getByProductLineId: createApiCallSelectors<number, ICommissionPlanRuleState>(
    commissionPlanRuleFeatureState,
    'getByProductLineId',
  ),
};
