import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, take } from 'rxjs/operators';
import { CarReferenceExactModelService } from '../../../../shared/store/car-reference-exact-model/car-reference-exact-model.service';
import { CarReferenceExactModelModel } from '../../../../shared/store/car-reference-exact-model/types';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-car-reference-exact-model-details',
  templateUrl: './car-reference-exact-model-details.component.html',
  styleUrls: ['./car-reference-exact-model-details.component.scss'],
})
export class CarReferenceExactModelDetailsComponent
  implements OnInit, OnDestroy
{
  carReferenceExactModelId: number;
  carReferenceExactModelModel$: Observable<CarReferenceExactModelModel>;
  view$: Observable<View>;

  @Input() modalId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly carReferenceExactModelService: CarReferenceExactModelService,
  ) {
    this.carReferenceExactModelModel$ =
      this.carReferenceExactModelService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.carReferenceExactModelId = this.route.snapshot.params['id'];

    if (this.modalId) this.carReferenceExactModelId = this.modalId;

    this.carReferenceExactModelService.getById.call(
      this.carReferenceExactModelId.toString(),
    );

    this.carReferenceExactModelModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@carReferenceExactModelDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'carReferenceExactModelEdit') {
          this.router.navigate([
            'Crm/CarReferenceExactModels/Edit/',
            this.carReferenceExactModelId,
          ]);
        }
        if (x == 'carReferenceExactModelDelete') {
          this.onDelete();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  onDelete() {
    let result$ = this.carReferenceExactModelService.delete.call(
      this.carReferenceExactModelId.toString(),
    );

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.carReferenceExactModelService.delete.reset();
      this.router.navigate(['Crm/CarReferenceExactModels/List']);
    });
  }

  ngOnDestroy(): void {
    this.carReferenceExactModelService.getById.reset();
    this.carReferenceExactModelService.delete.reset();
    this.breadcrumbService.set('@carReferenceExactModelDetails', ' ');
  }
}
