<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="glossaryForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="glossaryForm">

      <div class="card-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  class="form-control form-title required"
                  [placeholder]="'GLOSSARY_PLACEHOLDER_NAME' | translate"
                  id="name"
                  name="name"
                  type="name"
                  value=""
                  [maxLength]="80"
                  formControlName="name"
                />
                <span
                  class="field-validation-valid text-danger"
                  id="Name_validationMessage"
                ></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="url" translate
                >GLOSSARY_LBL_URL</label>
                <div class="col">
                  <input
                    [placeholder]="'GLOSSARY_PLACEHOLDER_URL' | translate"
                    class="form-control form-control-sm"
                    id="url"
                    name="url"
                    type="text"
                    value=""
                    formControlName="url"
                  />
                  <span
                    class="field-validation-valid text-danger"
                    id="Url_validationMessage"
                  ></span>
                </div>
              </div>
            </div>
            <!--                        <div class="col">-->
            <!--                            <div class="form-group row">-->
            <!--                                <label class="col-4 col-form-label-sm"></label>-->
            <!--                                <div class="col">-->
            <!--                              <span class="form-control-plaintext form-control-sm"></span>-->
            <!--                                </div>-->
            <!--                            </div>-->

            <!--                            <div class="form-group row">-->
            <!--                                <label class="col-4 col-form-label-sm"></label>-->
            <!--                                <div class="col">-->
            <!--                                <span class="form-control-plaintext form-control-sm"></span>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                >GLOSSARY_LBL_PAGECONTENT</label>
                <div class="NgxEditor__Wrapper card card-light card-body">
                  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                  </ngx-editor-menu>
                  <ngx-editor
                    [editor]="editor"
                    formControlName="pageContent"
                    [disabled]="false"
                    [placeholder]="'GLOSSARY_PLACEHOLDER_CONTENT' | translate"
                  ></ngx-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
