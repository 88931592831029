import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-car-reference-exact-model-edit',
  templateUrl: './car-reference-exact-model-edit.component.html',
  styleUrls: ['./car-reference-exact-model-edit.component.scss'],
})
export class CarReferenceExactModelEditComponent implements OnInit {
  carReferenceExactModelId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.carReferenceExactModelId = this.route.snapshot.params['id'];
  }
}
