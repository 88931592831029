import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { take } from 'rxjs/operators';
import { CarReferenceBrandModel } from '../../../../shared/store/car-reference-brand/types';
import { CarReferenceBrandService } from '../../../../shared/store/car-reference-brand/car-reference-brand.service';

@UntilDestroy()
@Component({
  selector: 'app-car-reference-brand-form-content',
  templateUrl: './car-reference-brand-form-content.component.html',
  styleUrls: ['./car-reference-brand-form-content.component.scss'],
})
export class CarReferenceBrandFormContentComponent
  implements OnInit, OnDestroy
{
  view$: Observable<View>;
  carReferenceBrandModel: Observable<CarReferenceBrandModel>;
  createCarReferenceBrandInstance$: Observable<CarReferenceBrandModel>;

  carReferenceBrandForm: FormGroup;

  isEditMode: boolean = false;
  carReferenceBrandId: string;

  constructor(
    private readonly carReferenceBrandService: CarReferenceBrandService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.carReferenceBrandModel = this.carReferenceBrandService.getById.value$;
    this.createCarReferenceBrandInstance$ =
      this.carReferenceBrandService.createInstance.value$;
  }

  ngOnInit(): void {
    //Déclaration du formulaire
    this.carReferenceBrandForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
    });

    //Récupération de l'id via la route pour déterminer si mode Edit (true) ou Create (false)
    this.carReferenceBrandId = this.route.snapshot.params['id'];
    this.isEditMode = !!this.carReferenceBrandId;

    //On appelle les API en fonction du mode
    if (this.isEditMode) {
      this.carReferenceBrandService.getById.call(this.carReferenceBrandId);
    } else {
      // this.carReferenceBrandService.createInstance.call();
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'carReferenceBrandCreate' || x == 'carReferenceBrandUpdate')
          this.onSubmit();
        if (x == 'carReferenceBrandList') {
          this.formService.clear();
          this.router.navigate(['Crm/CarReferenceBrands/']);
        }
        if (x == 'carReferenceBrandDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Crm/CarReferenceBrands/Details',
            this.carReferenceBrandId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);

        if (!this.isEditMode)
          this.breadcrumbService.set(
            '@carReferenceBrandCreate',
            x.breadcrumbCreate,
          );
      });

    if (this.isEditMode) {
      this.carReferenceBrandModel
        .pipe(
          filter((x) => !!x),
          take(1),
        )
        .subscribe((x) => {
          this.breadcrumbService.set('@carReferenceBrandEdit', x.name);
          this.carReferenceBrandForm.patchValue({
            name: x.name,
          });
        });
    }

    if (!this.isEditMode) {
      // this.createCarReferenceBrandInstance$
      //   .pipe(
      //     filter((x) => !!x),
      //     take(1),
      //   )
      //   .subscribe((x) => {
      //     this.carReferenceBrandForm?.patchValue({
      //       name: x.name,
      //     });
      //   });
    }
  }

  onSubmit() {
    if (this.carReferenceBrandForm.invalid) {
      this.carReferenceBrandForm.markAllAsTouched();
      this.formService.setEntityErrors('CAR_REFERENCE_BRAND');
      this.formService.countErrors(this.carReferenceBrandForm, true);
      return;
    }

    this.formService.clear();

    const carReferenceBrandModel: CarReferenceBrandModel = {
      id: this.carReferenceBrandId ? +this.carReferenceBrandId : 0,
      name: this.carReferenceBrandForm.value.name!,
    };

    let result$: Observable<CarReferenceBrandModel>;
    if (this.isEditMode)
      result$ = this.carReferenceBrandService.update.call(
        carReferenceBrandModel,
      );
    else
      result$ = this.carReferenceBrandService.add.call(carReferenceBrandModel);

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Crm/CarReferenceBrands/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.carReferenceBrandService.add.reset();
    this.carReferenceBrandService.update.reset();

    // if (this.isEditMode) {
    //   this.activityService.getById.reset();
    // } else {
    //   this.activityService.createInstance.reset();
    // }

    this.breadcrumbService.set('@carReferenceBrandEdit', ' ');
    this.breadcrumbService.set('@carReferenceBrandCreate', ' ');
  }
}
