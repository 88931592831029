import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { CarReferenceExactModelModel } from './types';
import { carReferenceExactModelActions } from './car-reference-exact-model.action';

export interface ICarReferenceExactModelState
  extends IState<CarReferenceExactModelModel> {
  getById: GenericState<CarReferenceExactModelModel>;
  createInstance: GenericState<CarReferenceExactModelModel>;
  add: GenericState<CarReferenceExactModelModel>;
  update: GenericState<CarReferenceExactModelModel>;
  delete: GenericState<boolean>;
}

export const carReferenceExactModelInitialState: ICarReferenceExactModelState = {
  getById: new GenericState<CarReferenceExactModelModel>(),
  createInstance: new GenericState<CarReferenceExactModelModel>(),
  add: new GenericState<CarReferenceExactModelModel>(),
  update: new GenericState<CarReferenceExactModelModel>(),
  delete: new GenericState<boolean>(),
};

const carReferenceExactModelReducers = [
  ...onApiCall<CarReferenceExactModelModel>(
    carReferenceExactModelActions.getById,
    'getById',
  ),
  ...onApiCall<CarReferenceExactModelModel>(
    carReferenceExactModelActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<CarReferenceExactModelModel>(carReferenceExactModelActions.add, 'add'),
  ...onApiCall<CarReferenceExactModelModel>(
    carReferenceExactModelActions.update,
    'update',
  ),
  ...onApiCall<boolean>(carReferenceExactModelActions.delete, 'delete'),
];

export const carReferenceExactModelReducer = createReducer(
  carReferenceExactModelInitialState,
  ...carReferenceExactModelReducers,
) as ActionReducer<ICarReferenceExactModelState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return carReferenceExactModelReducer(state, action);
}
