import { DateTime } from 'luxon';

export const articleFeatureKey = 'article';
export class Article {
  id: number;
  name: string;
  url: string;
  description: string;
  pageContent: string;
  creationDate: Date;
  publicationDate: Date;
}

export type ArticlePost = {
  name: string;
  url: string;
  description: string;
  pageContent: string;
  thumbnail: File;
  header: File;
  imageContent: File;
};

export type ArticleUpdate = {
  id: string;
  name: string;
  url: string;
  description: string;
  pageContent: string;
  thumbnail?: File;
  header?: File;
  imageContent?: File;
};

export type ArticlePublish = {
  publicationDate: DateTime;
};
