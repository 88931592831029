import { Action } from '../view/types';

export const scraperFeatureKey = 'scraper';

export type CounselorModel = {
  id: number;
  username: string;
  password: string | null;
  userId: number;
  ocealizInsuranceId: number;
  ocealizInsuranceLabel: string;
  ocealizInsuranceExternalAccountType: ExternalAccountType;
  ocealizInsuranceExternalAccountTypeLoc: string;
  isPrimary: boolean;
  actions: Action[];
};

export type InsuranceModel = {
  id: number;
  label: string;
  name: string;
  username: string;
  password: string;
  externalAccountType: ExternalAccountType;
  externalAccountTypeLoc: string;
  salesTeamId: number;
  actions: Action[];
};

export type RequestStoryModel = {
  gescoUuid: string;
  errors: string[];
  status: string;
  ocealizUuid: string;
  proformaUuid: string;
  insurance: InsuranceModel;
  duration: number;
  uuid: string;
  createCustomerData?: string | null;
  formuleData?: string | null;
  driverData?: string | null;
  brandData?: string | null;
  priceData?: string | null;
  validationData?: string | null;
  pdfLink: string;
  monthlyPremium: number;
  proformaCreationDate: Date;
};

export type ExternalAccountType = 'ocealiz';
export type OcealizAccountType = 'Insurance' | 'Counselor';
