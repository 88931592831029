import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from '../../../../common/store/generic.reducer';
import { GenericState, IState } from '../../../../common/store/types';
import { CommissionPlanRuleModel } from './types';
import { commissionPlanRuleActions } from './commission-plan-rule.actions';

export interface ICommissionPlanRuleState
  extends IState<CommissionPlanRuleModel> {
  getById: GenericState<CommissionPlanRuleModel>;
  getByProductLineId: GenericState<CommissionPlanRuleModel[]>;
}

export const commissionPlanRuleInitialState: ICommissionPlanRuleState = {
  getById: new GenericState<CommissionPlanRuleModel>(),
  getByProductLineId: new GenericState<CommissionPlanRuleModel[]>(),
};

const commissionPlanRuleReducers = [
  ...onApiCall<CommissionPlanRuleModel>(
    commissionPlanRuleActions.getById,
    'getById',
  ),
  ...onApiCall<CommissionPlanRuleModel[]>(
    commissionPlanRuleActions.getByProductLineId,
    'getByProductLineId',
  ),
];

export const commissionPlanRuleReducer = createReducer(
  commissionPlanRuleInitialState,
  ...commissionPlanRuleReducers,
) as ActionReducer<ICommissionPlanRuleState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return commissionPlanRuleReducer(state, action);
}
