import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarReferenceModelRoutingModule } from './car-reference-model-routing.module';
import { CarReferenceModelCreateComponent } from './car-reference-model-create/car-reference-model-create.component';
import { CarReferenceModelDetailsComponent } from './car-reference-model-details/car-reference-model-details.component';
import { CarReferenceModelEditComponent } from './car-reference-model-edit/car-reference-model-edit.component';
import { CarReferenceModelFormContentComponent } from './car-reference-model-form-content/car-reference-model-form-content.component';
import { SharedModule } from '../../../shared/shared.module';
import { CarReferenceModelTabsDetailsComponent } from './car-reference-model-details/car-reference-model-tabs-details/car-reference-model-tabs-details.component';
import { CarReferenceModelDetailsExactModelComponent } from './car-reference-model-details/car-reference-model-details-tab/car-reference-model-details-exact-model/car-reference-model-details-exact-model.component';

@NgModule({
  declarations: [
    CarReferenceModelCreateComponent,
    CarReferenceModelDetailsComponent,
    CarReferenceModelEditComponent,
    CarReferenceModelFormContentComponent,
    CarReferenceModelTabsDetailsComponent,
    CarReferenceModelDetailsExactModelComponent,
  ],
  imports: [CommonModule, CarReferenceModelRoutingModule, SharedModule],
})
export class CarReferenceModelModule {}
