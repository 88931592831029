import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { CarReferenceModelModel } from './types';
import { carReferenceModelActions } from './car-reference-model.action';

export interface ICarReferenceModelState
  extends IState<CarReferenceModelModel> {
  getById: GenericState<CarReferenceModelModel>;
  createInstance: GenericState<CarReferenceModelModel>;
  add: GenericState<CarReferenceModelModel>;
  update: GenericState<CarReferenceModelModel>;
  delete: GenericState<boolean>;
}

export const carReferenceModelInitialState: ICarReferenceModelState = {
  getById: new GenericState<CarReferenceModelModel>(),
  createInstance: new GenericState<CarReferenceModelModel>(),
  add: new GenericState<CarReferenceModelModel>(),
  update: new GenericState<CarReferenceModelModel>(),
  delete: new GenericState<boolean>(),
};

const carReferenceModelReducers = [
  ...onApiCall<CarReferenceModelModel>(
    carReferenceModelActions.getById,
    'getById',
  ),
  ...onApiCall<CarReferenceModelModel>(
    carReferenceModelActions.createInstance,
    'createInstance',
  ),
  ...onApiCall<CarReferenceModelModel>(carReferenceModelActions.add, 'add'),
  ...onApiCall<CarReferenceModelModel>(
    carReferenceModelActions.update,
    'update',
  ),
  ...onApiCall<boolean>(carReferenceModelActions.delete, 'delete'),
];

export const carReferenceModelReducer = createReducer(
  carReferenceModelInitialState,
  ...carReferenceModelReducers,
) as ActionReducer<ICarReferenceModelState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return carReferenceModelReducer(state, action);
}
