import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';
import {
  Component,
  ComponentDef,
  ComponentType,
} from '../store/typeahead/types';
import { JobCreateComponent } from '../../features/human-resources/job/job-create/job-create.component';
import { JobDetailsComponent } from '../../features/human-resources/job/job-details/job-details.component';
import { CurrencyCreateContentComponent } from '../../features/configuration/currency/currency-create/currency-create-content/currency-create-content.component';
import { Entity } from '../store/view/types';
import { CurrencyDetailsContentComponent } from '../../features/configuration/currency/currency-details/currency-details-content/currency-details-content.component';
import { ContactDetailsContentComponent } from '../../features/contacts/contact/contact-details/contact-details-content/contact-details-content.component';
import { PasswordManageContentComponent } from '../../features/account/password-manage-content/password-manage-content.component';
import { ContactFormContentComponent } from '../../features/contacts/contact/shared/contact-form-content/contact-form-content.component';
import { LeadQuotesComponent } from '../components/lead-quotes/lead-quotes.component';
import { LeadLostReasonContentComponent } from 'src/app/features/crm/lead/lead-lost-reason-content/lead-lost-reason-content.component';
import { DocumentFormContentComponent } from '../components/document/document-form-content/document-form-content.component';
import { SelectDocumentTypeEditContentComponent } from '../../features/insurance/product-category/select-document-type-edit-content/select-document-type-edit-content.component';
import { AccountMoveAddContentComponent } from '../components/account-moves/account-move-add-content/account-move-add-content.component';
import { AccountMoveAssignContentComponent } from '../components/account-moves/account-move-assign-content/account-move-assign-content.component';
import { AccountMoveAssignDetailsContentComponent } from '../components/account-moves/account-move-assign-details-content/account-move-assign-details-content.component';
import { PaymentAddContentComponent } from '../components/account-moves/payment-add-content/payment-add-content.component';
import { SlipAddContentComponent } from '../components/account-moves/slip-add-content/slip-add-content.component';
import { AccountMoveAssignRepaymentContentComponent } from '../components/account-moves/account-move-assign-repayment-content/account-move-assign-repayment-content.component';
import { AccountMoveAssignSlipContentComponent } from '../components/account-moves/account-move-assign-slip-content/account-move-assign-slip-content.component';
import { AccountMoveAssignSlipDetailsContentComponent } from '../components/account-moves/account-move-assign-slip-details-content/account-move-assign-slip-details-content.component';
import { CounselorChoiceQuoteOcealizComponent } from '../../features/crm/lead/lead-details/lead-details-tab/lead-details-quotes-scraper/counselor-choice-quote-ocealiz/counselor-choice-quote-ocealiz.component';
import { SalesTeamExternalAccountCreateContentComponent } from '../../features/crm/sales-team/sales-team-details/sales-team-details-tab/sales-team-details-external-account/sales-team-external-account-create-content/sales-team-external-account-create-content.component';
import { UpdatePasswordAccountComponent } from '../components/scraper/update-password-account/update-password-account.component';
import { UserExternalAccountCreateContentComponent } from '../../features/configuration/user/user/user-details/user-details-tab/user-details-external-account/user-external-account-create-content/user-external-account-create-content.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private output = new Subject<string>();
  private components: ComponentType[] = [
    {
      modelType: Entity.Job,
      create: JobCreateComponent,
      details: JobDetailsComponent,
    },
    {
      modelType: Entity.Currency,
      create: CurrencyCreateContentComponent,
      details: CurrencyDetailsContentComponent,
    },
  ];
  private componentsRef: ComponentDef[] = [
    {
      name: 'currencycreatecontentcomponent',
      type: CurrencyCreateContentComponent,
    },
    {
      name: 'currencydetailscontentcomponent',
      type: CurrencyDetailsContentComponent,
    },
    {
      name: 'contactcreatecontentcomponent',
      type: ContactFormContentComponent,
    },
    {
      name: 'contactdetailscontentcomponent',
      type: ContactDetailsContentComponent,
    },
    {
      name: 'passwordmanagecontentcomponent',
      type: PasswordManageContentComponent,
    },
    {
      name: 'leadlostreasoncontentcomponent',
      type: LeadLostReasonContentComponent,
    },
    {
      name: 'leadquotescomponent',
      type: LeadQuotesComponent,
    },
    {
      name: 'documenteditcontentcomponent',
      type: DocumentFormContentComponent,
    },
    {
      name: 'selectdocumenttypeeditcontentcomponent',
      type: SelectDocumentTypeEditContentComponent,
    },
    {
      name: 'paymentaddcontentcomponent',
      type: PaymentAddContentComponent,
    },
    {
      name: 'accountmoveaddcontentcomponent',
      type: AccountMoveAddContentComponent,
    },
    {
      name: 'accountmoveassigncontentcomponent',
      type: AccountMoveAssignContentComponent,
    },
    {
      name: 'accountmoveassigndetailscontentcomponent',
      type: AccountMoveAssignDetailsContentComponent,
    },
    {
      name: 'slipaddcontentcomponent',
      type: SlipAddContentComponent,
    },
    {
      name: 'accountmoveassignrepaymentcontentcomponent',
      type: AccountMoveAssignRepaymentContentComponent,
    },
    {
      name: 'accountmoveassignslipcontentcomponent',
      type: AccountMoveAssignSlipContentComponent,
    },
    {
      name: 'accountmoveassignslipdetailscontentcomponent',
      type: AccountMoveAssignSlipDetailsContentComponent,
    },
    {
      name: 'counselorchoicequoteocealizcomponent',
      type: CounselorChoiceQuoteOcealizComponent,
    },
    {
      name: 'salesteamexternalaccountcreatecontentcomponent',
      type: SalesTeamExternalAccountCreateContentComponent,
    },
    {
      name: 'userexternalaccountcreatecontentcomponent',
      type: UserExternalAccountCreateContentComponent,
    },
    {
      name: 'updatepasswordaccountcomponent',
      type: UpdatePasswordAccountComponent,
    },
  ];

  constructor() {}

  getObservable() {
    return this.output.asObservable();
  }

  outputFromDynamicComponent(data: string) {
    this.output.next(data);
  }

  getComponent(entity: Entity, type: 'create' | 'details') {
    let idx = this.components.findIndex(
      (component) => component.modelType === entity,
    );
    if (idx < 0) return undefined;
    return this.components[idx][type];
  }

  getComponentClass(name: string) {
    let idx = this.componentsRef.findIndex(
      (component) => component.name === name.toLowerCase(),
    );
    if (idx < 0) return undefined;
    return this.componentsRef[idx].type;
  }

  declareComponent(name: string, type: Type<Component>) {
    this.componentsRef.push({ name: name.toLowerCase(), type: type });
  }
}
