import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { filter, take } from 'rxjs/operators';
import { CarReferenceModelService } from '../../../../shared/store/car-reference-model/car-reference-model.service';
import { CarReferenceModelModel } from '../../../../shared/store/car-reference-model/types';
import { filterTrue } from '../../../../shared/pipe/rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-car-reference-model-details',
  templateUrl: './car-reference-model-details.component.html',
  styleUrls: ['./car-reference-model-details.component.scss'],
})
export class CarReferenceModelDetailsComponent implements OnInit, OnDestroy {
  carReferenceModelId: number;
  carReferenceModelModel$: Observable<CarReferenceModelModel>;
  view$: Observable<View>;

  @Input() modalId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly carReferenceModelService: CarReferenceModelService,
  ) {
    this.carReferenceModelModel$ = this.carReferenceModelService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.carReferenceModelId = this.route.snapshot.params['id'];

    if (this.modalId) this.carReferenceModelId = this.modalId;

    this.carReferenceModelService.getById.call(
      this.carReferenceModelId.toString(),
    );

    this.carReferenceModelModel$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@carReferenceModelDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'carReferenceModelEdit') {
          this.router.navigate([
            'Crm/CarReferenceModels/Edit/',
            this.carReferenceModelId,
          ]);
        }
        if (x == 'carReferenceModelDelete') {
          this.onDelete();
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  onDelete() {
    let result$ = this.carReferenceModelService.delete.call(
      this.carReferenceModelId.toString(),
    );

    result$.pipe(filterTrue(), take(1)).subscribe((result) => {
      this.carReferenceModelService.delete.reset();
      this.router.navigate(['Crm/CarReferenceModels/List']);
    });
  }

  ngOnDestroy(): void {
    this.carReferenceModelService.getById.reset();
    this.carReferenceModelService.delete.reset();
    this.breadcrumbService.set('@carReferenceModelDetails', ' ');
  }
}
