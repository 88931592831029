export const commissionPlanRuleFeatureKey = 'commissionPlanRule';

export class CommissionPlanRuleModel {
  id: number;
  name: string;
  sequence: number;
  insuranceCompanyId?: number;
  productCategoryId?: number;
  productLineId?: number;
  productId?: number;
  value: number;
  commissionPlanRuleValueType: CommissionPlanRuleValueType;
  commissionPlanRuleType: CommissionPlanRuleType;
  commissionPlanRuleSource: CommissionPlanRuleSource;
}

export type CommissionPlanRuleValueType =
  | 'CommissionRateFirstYear'
  | 'CommissionRateTerm'
  | 'CustomerApplicationFeeMinimum'
  | 'CustomerApplicationFeeMaximum'
  | 'CustomerApplicationFeeDefault'
  | 'VendorApplicationFeeMinimum'
  | 'VendorApplicationFeeMaximum'
  | 'VendorApplicationFeeDefault';

export type CommissionPlanRuleType = 'Percentage' | 'Monetary';

export type CommissionPlanRuleSource = 'RecurringFees' | 'ApplicationFees';
