import { createApiCallActions } from '../../../common/store/generic.actions';
import { CarReferenceExactModelModel } from './types';

export const carReferenceExactModelActions = {
  getById: createApiCallActions<CarReferenceExactModelModel>(
    'carReferenceExactModel',
    'getById',
  ),
  createInstance: createApiCallActions<CarReferenceExactModelModel>(
    'carReferenceExactModel',
    'createInstance',
  ),
  add: createApiCallActions<CarReferenceExactModelModel>('carReferenceExactModel', 'add'),
  update: createApiCallActions<CarReferenceExactModelModel>(
    'carReferenceExactModel',
    'update',
  ),
  delete: createApiCallActions<boolean>('carReferenceExactModel', 'delete'),
};

export type CarReferenceExactModelAction = typeof carReferenceExactModelActions;
