import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { DataType, DropdownStyle } from 'src/app/shared/store/typeahead/types';
import { Entity } from 'src/app/shared/store/view/types';
import { CarInsuranceContractModel } from '../../../../../../shared/store/contract/types';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { formatDate } from 'src/app/shared/helper/datehelper';

@Component({
  selector: 'app-contract-form-driver',
  templateUrl: './contract-form-driver.component.html',
  styleUrls: ['./contract-form-driver.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormDriverComponent implements OnInit {
  form: FormGroup;
  carDetail$: Observable<CarInsuranceContractModel>;
  carInsuranceContractModel$: Observable<CarInsuranceContractModel>;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly DataType = DataType;
  constructor(
    private readonly contractService: ContractService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.carDetail$ = this.contractService.getCarDetail.value$;
    this.carInsuranceContractModel$ =
      this.contractService.getCarContractModel.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;

    this.form.addControl(
      'driverFormGroup',
      this.formBuilder.group({
        driverGender: [undefined, [Validators.required]],
        driverLastname: ['', [Validators.required]],
        driverFirstname: ['', [Validators.required]],
        driverBirthdate: [undefined, [Validators.required]],
        driverZipCode: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ],
        ],
        driverCity: [undefined, [Validators.required]],
        driverAddress: [''],
        driverMaritalStatus: [undefined, [Validators.required]],
        driverLicenseDate: [undefined, [Validators.required]],
        driverYearsInsured: [undefined],
        driverBonusMalus: [undefined],
        driverMonthInsuranceInterruption: [undefined],
        driverInsuranceTermination: [undefined],
        driverLicenseAnnulation: [''],
        driverNoLicenseConviction: [''],
        driverLicenseCancel: [''],
        driverLicenseCancelMotive: [undefined],
        driverLicenseCancelDate: [undefined],
        driverLicenseSuspension: [''],
        driverLicenseSuspensionMotive: [undefined],
        driverLicenseSuspensionDate: [undefined],
        driverLongestSuspension: [undefined],
        driverAlcoholDrugControl: [''],
        driverVehicleRegistrationHolder: [true],
        driverDisasters: this.formBuilder.array([]),
      }),
    );
    this.carInsuranceContractModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          driverFormGroup: {
            driverGender: x.driverGender,
            driverLastname: x.driverLastName,
            driverFirstname: x.driverFirstName,
            driverBirthdate: formatDate(x.driverBirthdate, 'ToInverseSqlDate'),
            driverZipCode: x.driverZipCode,
            driverCity: x.driverCity,
            driverAddress: x.driverAddress,
            driverMaritalStatus: x.driverMaritalStatus,
            driverLicenseDate: formatDate(x.licenseDate, 'ToInverseSqlDate'),
            driverYearsInsured: x.yearsInsured,
            driverBonusMalus: x.bonusMalus,
            driverMonthInsuranceInterruption: x.monthInsuranceInterruption,
            driverInsuranceTermination: x.insuranceTermination,
            driverLicenseAnnulation: x.licenseAnnulation === 'true',
            driverNoLicenseConviction: x.noLicenseConviction,
            driverLicenseCancel: x.licenseCancel,
            driverLicenseCancelMotive: x.licenseCancelMotive,
            driverLicenseCancelDate: formatDate(
              x.licenseCancelDate,
              'ToInverseSqlDate',
            ),
            driverLicenseSuspension: x.licenseSuspension,
            driverLicenseSuspensionMotive: x.licenseSuspensionMotive,
            driverLicenseSuspensionDate: formatDate(
              x.licenseSuspensionDate,
              'ToInverseSqlDate',
            ),
            driverLongestSuspension: x.longestSuspension,
            driverAlcoholDrugControl: x.alcoholDrugControl,
            driverVehicleRegistrationHolder:
              x.isDriverVehiculeRegistrationHolder,
          },
        });
      });

    this.carDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          driverFormGroup: {
            driverGender: x.driverGender,
            driverLastname: x.driverLastName,
            driverFirstname: x.driverFirstName,
            driverBirthdate: formatDate(x.driverBirthdate, 'ToInverseSqlDate'),
            driverZipCode: x.driverZipCode,
            driverCity: x.driverCity,
            driverAddress: x.driverAddress,
            driverMaritalStatus: x.driverMaritalStatus,
            driverLicenseDate: formatDate(x.licenseDate, 'ToInverseSqlDate'),
            driverYearsInsured: x.yearsInsured,
            driverBonusMalus: x.bonusMalus,
            driverMonthInsuranceInterruption: x.monthInsuranceInterruption,
            driverInsuranceTermination: x.insuranceTermination,
            driverLicenseAnnulation: x.licenseAnnulation === 'true',
            driverNoLicenseConviction: x.noLicenseConviction,
            driverLicenseCancel: x.licenseCancel,
            driverLicenseCancelMotive: x.licenseCancelMotive,
            driverLicenseCancelDate: formatDate(
              x.licenseCancelDate,
              'ToInverseSqlDate',
            ),
            driverLicenseSuspension: x.licenseSuspension,
            driverLicenseSuspensionMotive: x.licenseSuspensionMotive,
            driverLicenseSuspensionDate: formatDate(
              x.licenseSuspensionDate,
              'ToInverseSqlDate',
            ),
            driverLongestSuspension: x.longestSuspension,
            driverAlcoholDrugControl: x.alcoholDrugControl,
            driverVehicleRegistrationHolder:
              x.isDriverVehiculeRegistrationHolder,
          },
        });

        const disasters = x.carDisasters;
        var dataDisaster: any[] = [];
        for (let i = 0; i < disasters.length; i++) {
          this.addDisaster();
          dataDisaster.push({
            carDisasterReason: disasters[i].carDisasterReason,
            carDisasterResponsiblePerson:
              disasters[i].carDisasterResponsiblePerson,
            carDisasterDate: formatDate(
              disasters[i].carDisasterDate,
              'ToInverseSqlDate',
            ),
          });
        }
        this.driverDisasters.patchValue(dataDisaster);
      });
  }

  get driverDisasters() {
    let driverFormGroup = this.form.controls['driverFormGroup'] as FormGroup;

    return driverFormGroup.controls['driverDisasters'] as FormArray;
  }

  addDisaster() {
    const disasterForm = this.formBuilder.group({
      carDisasterDate: [],
      carDisasterReason: [''],
      carDisasterResponsiblePerson: [''],
    });

    this.driverDisasters.push(disasterForm);
  }

  removeDisaster(index: number) {
    this.driverDisasters.removeAt(index);
  }
}
