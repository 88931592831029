import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-car-reference-brand-edit',
  templateUrl: './car-reference-brand-edit.component.html',
  styleUrls: ['./car-reference-brand-edit.component.scss'],
})
export class CarReferenceBrandEditComponent implements OnInit {
  carReferenceBrandId: string;

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.carReferenceBrandId = this.route.snapshot.params['id'];
  }
}
