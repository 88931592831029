import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  carReferenceModelInitialState,
  carReferenceModelReducer,
  ICarReferenceModelState,
} from './car-reference-model.reducer';
import { carReferenceModelFeatureKey } from './types';
import { CarReferenceModelEffects } from './car-reference-model.effects';
import { CarReferenceModelService } from './car-reference-model.service';
import { CarReferenceModelApiService } from './car-reference-model.api.service';

@NgModule({
  imports: [
    StoreModule.forFeature<ICarReferenceModelState>(
      carReferenceModelFeatureKey,
      carReferenceModelReducer,
      {
        initialState: carReferenceModelInitialState,
      },
    ),
    EffectsModule.forFeature([CarReferenceModelEffects]),
  ],
  providers: [CarReferenceModelApiService, CarReferenceModelService],
})
export class StoreCarReferenceModelModule {}
