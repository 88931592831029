import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Editor, Toolbar } from 'ngx-editor';
import { filter, Observable } from 'rxjs';
import { GlossaryService } from 'src/app/shared/store/glossary/glossary.service';
import { Glossary } from 'src/app/shared/store/glossary/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-glossary-edit',
  templateUrl: './glossary-edit.component.html',
  styleUrls: ['./glossary-edit.component.scss'],
})
export class GlossaryEditComponent implements OnInit, OnDestroy {
  glossary$: Observable<Glossary>;
  glossaryForm: FormGroup;

  editor: Editor;
  toolbar: Toolbar;

  view$: Observable<View>;

  constructor(
    private readonly glossaryService: GlossaryService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.glossary$ = this.glossaryService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.glossaryForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(80)]],
      url: ['', [Validators.required]],
      pageContent: ['', [Validators.required]],
      meta: [''],
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'glossaryUpdate') this.onSubmit();
        if (x == 'glossaryDetails') {
          this.formService.clear();
          this.router.navigate([
            '/Cms/Glossaries/Details',
            this.route.snapshot.params['id'],
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });

    this.glossaryService.getById.call(this.route.snapshot.params['id']);

    this.glossary$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@glossaryEdit', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@list', x.title);
      });

    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
      ['link', 'image'],
    ];

    this.glossary$.pipe().subscribe((x) => {
      this.glossaryForm.patchValue({
        name: x.name,
        url: x.url,
        pageContent: x.pageContent,
      });
    });
  }

  onSubmit(): void {
    if (this.glossaryForm.invalid) {
      this.glossaryForm.markAllAsTouched();
      this.formService.countErrors(this.glossaryForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.glossaryService.updateGlossary.call({
      id: this.route.snapshot.params['id'],
      name: this.glossaryForm.value.name!,
      url: this.glossaryForm.value.url!,
      pageContent: this.glossaryForm.value.pageContent!,
      meta: this.glossaryForm.value.meta!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Cms/Glossaries/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.glossaryService.updateGlossary.reset();
    this.breadcrumbService.set('@glossaryDetails', ' ');
  }
}
