<form [formGroup]="updatePasswordForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="updatePasswordForm">
  <button type="submit" hidden="hidden"></button>

  <div class="row">
    <div class="form-group row">
      <label class="col-4 col-form-label-sm" translate
      >EXTERNAL_ACCOUNT_LBL_PASSWORD</label
      >
      <div class="col">
        <input
          class="form-control form-control-sm"
          type="password"
          [placeholder]="''"
          formControlName="password"
          autocomplete="new-password"
          checkFormFieldValidity
        />
      </div>
    </div>
  </div>
</form>
