<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
>
  <div class="d-flex flex-column h-100">
    <ng-container *ngIf="this.insurances$ | async as insurances">
      <div class="row mt-2" *ngIf="insurances.length == 0">
        <div class="col-12 col-md">
          <div class="ps-3">
            <button
              type="button"
              [class]="ButtonType.Primary | buttontypepipe"
              class="btn-sm btn"
              translate
              (click)="openModalAddInsurance()"
            >
              SALES_TEAM_LBL_EXTERNAL_ACCOUNT_ADD
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-2" *ngIf="insurances.length > 0">
        <div class="col">
          <table class="table table-striped border-bottom">
            <thead>
            <tr>
              <th>
                <a translate>SALES_TEAM_LBL_EXTERNAL_ACCOUNT_COMPANY</a>
              </th>
              <th>
                <a translate>SALES_TEAM_LBL_EXTERNAL_ACCOUNT_USERNAME</a>
              </th>
              <th>
                <a translate>SALES_TEAM_LBL_EXTERNAL_ACCOUNT_ACTIONS</a>
              </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let insurance of insurances;">
              <td>{{ insurance.externalAccountTypeLoc }}</td>
              <td>{{ insurance.username }}</td>
              <td>
                <ng-container *ngFor="let action of insurance.actions; let last = last;">
                  <a role='button'
                     (click)="this.triggerAction(action.name, insurance.id)"
                     translate>{{ action.actionName }}{{ last ? '' : ' | ' }}</a>
                </ng-container>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </ng-container>
  </div>
</div>
