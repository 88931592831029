import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import {
  CarReferenceBrandAction,
  carReferenceBrandActions,
} from './car-reference-brand.action';
import { CarReferenceBrandApiService } from './car-reference-brand.api.service';

@Injectable()
export class CarReferenceBrandEffects {
  constructor(
    private readonly carReferenceBrandApiService: CarReferenceBrandApiService,
    private readonly action$: Actions<CarReferenceBrandAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    carReferenceBrandActions.getById,
    this.carReferenceBrandApiService.getById,
  );
  createInstance = createApiCallEffects(
    this,
    this.action$,
    carReferenceBrandActions.createInstance,
    this.carReferenceBrandApiService.createInstance,
  );
  add = createApiCallEffects(
    this,
    this.action$,
    carReferenceBrandActions.add,
    this.carReferenceBrandApiService.add,
  );
  update = createApiCallEffects(
    this,
    this.action$,
    carReferenceBrandActions.update,
    this.carReferenceBrandApiService.update,
  );
  delete = createApiCallEffects(
    this,
    this.action$,
    carReferenceBrandActions.delete,
    this.carReferenceBrandApiService.delete,
  );
}
