<form [formGroup]="carReferenceBrandForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="carReferenceBrandForm">
  <button type="submit" hidden="hidden"></button>
  <div class="row mb-2">
    <div class="col-6 mr-auto">
      <div class="form-group">
        <label class="form-title-label" for="name" translate>CAR_REFERENCE_BRAND_LBL_NAME</label>
        <input
          class="form-control form-title"
          id="name"
          name="name"
          [placeholder]="''"
          type="text"
          value=""
          formControlName="name"
          oninput="this.value = this.value.toUpperCase()"
          checkFormFieldValidity
        />
      </div>
    </div>
  </div>
</form>
