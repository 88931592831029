import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CounselorModel } from '../../../../../../../shared/store/scraper/types';
import { SubHeaderService } from '../../../../../../../shared/store/subheader/subheader.service';
import { ModalService } from '../../../../../../../shared/service/modal.service';
import { FormService } from '../../../../../../../shared/store/form/form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ScraperService } from '../../../../../../../shared/store/scraper/scraper.service';
import {
  ListItem,
  SourceEnum,
} from '../../../../../../../shared/store/typeahead/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Entity } from '../../../../../../../shared/store/view/types';

@UntilDestroy()
@Component({
  selector: 'app-counselor-choice-quote-ocealiz',
  templateUrl: './counselor-choice-quote-ocealiz.component.html',
  styleUrls: ['./counselor-choice-quote-ocealiz.component.scss'],
})
export class CounselorChoiceQuoteOcealizComponent implements OnInit, OnDestroy {
  counselorChoiceForm: FormGroup;

  @Input() source: SourceEnum = SourceEnum.Component;
  @Input({ required: true }) counselors: CounselorModel[];
  @Input({ required: true }) leadUniqueId: string;

  listItems: ListItem[] = [];

  protected readonly Entity = Entity;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly dynamicService: ModalService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly scraperService: ScraperService,
  ) {}

  ngOnInit(): void {
    this.scraperService.getCounselorsByUser.reset();

    this.counselorChoiceForm = this.formBuilder.group({
      counselorId: [undefined, [Validators.required]],
    });

    //Prepare data for typeahead
    const counselors = [...this.counselors].sort((a, b) => {
      if (a.isPrimary === b.isPrimary) {
        return 0;
      }

      if (a.isPrimary) {
        return -1;
      }

      if (b.isPrimary) {
        return 1;
      }
      return -1;
    });
    this.listItems = counselors.map((model) => {
      return <ListItem>{
        id: model.id,
        name: `Ocealiz -${model.ocealizInsuranceLabel} - ${model.username}`,
      };
    });

    this.counselorChoiceForm.patchValue({
      counselorId: this.listItems[0].id,
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'scrapingQuote') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit() {
    if (this.counselorChoiceForm.invalid) {
      this.counselorChoiceForm.markAllAsTouched();
      this.formService.setEntityErrors('COUNSELOR');
      this.formService.countErrors(this.counselorChoiceForm, true);
      return;
    }

    this.scraperService.scraping.call({
      leadUniqueId: this.leadUniqueId,
      ocealizCounselorId: this.counselorChoiceForm.value.counselorId,
    });

    this.dynamicService.outputFromDynamicComponent('1');
  }

  ngOnDestroy(): void {
    this.scraperService.scraping.reset();
  }
}
