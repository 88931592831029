import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ArticleCreateComponent } from './article-create/article-create.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleEditComponent } from './article-edit/article-edit.component';
import { ArticleRoutingModule } from './article-routing.module';
import { FullPathImagePipe } from '../../../shared/pipe/full-path-image.pipe';

@NgModule({
  declarations: [
    ArticleCreateComponent,
    ArticleDetailComponent,
    ArticleEditComponent,
  ],
  imports: [CommonModule, SharedModule, ArticleRoutingModule],
  providers: [FullPathImagePipe],
})
export class ArticleModule {}
