import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DataListingProviderComponent } from '../../../shared/components/data-listing-provider/data-listing-provider.component';
import { AuthentificationGuard } from '../../../shared/guard/authentification-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { Entity, HeaderMenuType } from '../../../shared/store/view/types';
import { ModuleId } from '../../../shared/store/navigation/types';
import { CarReferenceExactModelDetailsComponent } from './car-reference-exact-model-details/car-reference-exact-model-details.component';
import { CarReferenceExactModelCreateComponent } from './car-reference-exact-model-create/car-reference-exact-model-create.component';
import { CarReferenceExactModelEditComponent } from './car-reference-exact-model-edit/car-reference-exact-model-edit.component';

const routes: Routes = [
  {
    path: 'Crm/CarReferenceExactModels',
    data: {
      breadcrumb: { label: ' ', alias: 'list' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: DataListingProviderComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceExactModel,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: CarReferenceExactModelDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceExactModel,
          breadcrumb: { label: ' ', alias: 'carReferenceExactModelDetails' },
        },
      },
      {
        path: 'Create',
        component: CarReferenceExactModelCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceExactModel,
          breadcrumb: { label: ' ', alias: 'carReferenceExactModelCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: CarReferenceExactModelEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          moduleId: ModuleId.crm,
          entity: Entity.CarReferenceExactModel,
          breadcrumb: { label: ' ', alias: 'carReferenceExactModelEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CarReferenceExactModelRoutingModule {}
